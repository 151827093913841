import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const useStyles = makeStyles((theme) => ({
  achievementContainer: {
    height: 300,
    width: "100%",
    backgroundColor: "#ecf0f1",
    backgroundPosition: "center",
    paddingTop: 25,
    [theme.breakpoints.down("xs")]: {
      height: 230,
    },
  },

  countryFlag: {
    height: 200,
    width: 200,
    objectFit: "contain",
    // paddingLeft: 10,
    // paddingRight: 10,

    [theme.breakpoints.down("xs")]: {
      height: 150,
      width: 150,
    },
  },

  contWidth: {
    width: "80%",
    margin: "0 auto",

    marginRight: 100,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginLeft: 30,
    },
  },
  countryText: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: "bold",
    paddingTop: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));
const Countries = ({ home }) => {
  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };
  return (
    <div className={classes.achievementContainer}>
      <p className={classes.countryText}>Our Presence Globally</p>
      <div className={classes.contWidth}>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={200}
          keyBoardControl={true}
          // customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          <img
            src="https://static.wixstatic.com/media/e68f76_51fcb981a7434ed6a7c6710c976fc503~mv2.jpg/v1/fill/w_303,h_228,al_c,q_80,usm_0.66_1.00_0.01/e68f76_51fcb981a7434ed6a7c6710c976fc503~mv2.webp"
            className={classes.countryFlag}
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/e68f76_8d5d5c0d87e74aecbe6f500fdbeb8619~mv2.jpg/v1/fill/w_303,h_228,al_c,q_80,usm_0.66_1.00_0.01/e68f76_8d5d5c0d87e74aecbe6f500fdbeb8619~mv2.webp"
            className={classes.countryFlag}
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/e68f76_0b3dc9f8fba44c3e8f6aa80811e60a23~mv2.jpg/v1/fill/w_303,h_228,al_c,q_80,usm_0.66_1.00_0.01/e68f76_0b3dc9f8fba44c3e8f6aa80811e60a23~mv2.webp"
            className={classes.countryFlag}
            alt=""
          />
          <img
            src="https://static.wixstatic.com/media/e68f76_3ae2070d81b544bb9071e6158e89db40~mv2.jpg/v1/fill/w_303,h_228,al_c,q_80,usm_0.66_1.00_0.01/e68f76_3ae2070d81b544bb9071e6158e89db40~mv2.webp"
            className={classes.countryFlag}
            alt=""
          />
        </Carousel>
      </div>
    </div>
  );
};

export default Countries;
