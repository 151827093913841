import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Menu, MenuItem, IconButton } from "@material-ui/core";
import useWindowDimensions from "../helpers/useDimensions";
import { Link } from "react-router-dom";
import Sidebarnav from "./Sidebarnav";
import { MapPin } from "react-feather";
import { useHistory } from "react-router-dom";
import { CountryContext } from "./CountryContext";
const useStyles = makeStyles((theme) => ({
  header: {
    height: 60,
    width: "100%",
    backgroundColor: "#2c3e50",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },

  headerMob: {
    height: 60,
    width: "100%",
    backgroundColor: "#2c3e50",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  header2: {
    height: 60,
    width: "100%",
    backgroundColor: "white",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
  },
  navLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  navLink: {
    fontSize: 16,
    textTransform: "uppercase",
    marginTop: 17,
    color: "black",
    fontWeight: 600,
  },
}));

const countryWithFlags = {
  India: {
    name: "India",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
  },
  "United States": {
    name: "United States",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png",
  },
  Singapore: {
    name: "Singapore",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/1200px-Flag_of_Singapore.svg.png",
  },
  Canada: {
    name: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
  },
  "United Kingdom": {
    name: "United Kingdom",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/640px-Flag_of_the_United_Kingdom.svg.png",
  },
  Australia: {
    name: "Australia",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/b/b9/Flag_of_Australia.svg/1200px-Flag_of_Australia.svg.png",
  },
};

const Headernew = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { country, setCountry } = useContext(CountryContext);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCountryChange = (countryName) => {
    handleClose();
    setCountry(countryName);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={width > 600 ? classes.header : classes.header}>
        <Link to="/" className={classes.flexCenter}>
          <p
            style={{
              fontSize: 30,
              color: "white",
              fontWeight: "bold",
              marginLeft: width > 600 ? 0 : 15,
            }}
          >
            Live Sloka
          </p>
        </Link>

        <div style={{ position: "absolute", right: 10 }}>
          <div key={"right"}>
            <div
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={countryWithFlags[country]?.flag}
                style={{ height: 20, marginRight: 20 }}
                alt={country}
              />
              {width > 600 ? <MapPin /> : null}
            </div>
          </div>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {Object.keys(countryWithFlags).map((country) => (
            <MenuItem onClick={() => handleCountryChange(country)}>
              <img
                src={countryWithFlags[country].flag}
                style={{
                  height: 20,
                  width: 20,
                  objectFit: "cover",
                  marginRight: 10,
                }}
                alt=""
                srcset=""
              />
              {country}
            </MenuItem>
          ))}
        </Menu>

        {width > 600 ? null : (
          <div style={{ position: "absolute", left: 15 }}>
            <div key={"left"}>
              <Sidebarnav />
            </div>
          </div>
        )}
      </div>

      {width > 600 ? (
        <div className={classes.header2}>
          <Container>
            <div className={classes.navLinks}>
              <Link to="/" className={classes.navLink}>
                Home
              </Link>
              <Link to="/about" className={classes.navLink}>
                About
              </Link>
              <Link to="/gallery" className={classes.navLink}>
                Gallery
              </Link>
              <Link to="/booking" className={classes.navLink}>
                Book a demo
              </Link>
              <Link to="/teachers" className={classes.navLink}>
                Teachers
              </Link>
              <Link to="/apply" className={classes.navLink}>
                Careers
              </Link>
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default Headernew;
