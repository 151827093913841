import React from "react";
import TabsComp from "../Components/Tabscomp";

const CategorySubjects = () => {

  return (
    <div style={{ width: "95vw" }}>
        <TabsComp />
    </div>
  );
};

export default CategorySubjects;
