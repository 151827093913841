import React from "react";
import Headernew from "../Components/Headernew";

const VideoGallery = () => {
  return (
    <div>
      <Headernew />
      <div style={{ padding: "48.9% 0 0 0", position: "relative" }}>
        <iframe
          src="https://vimeo.com/showcase/8948722/embed"
          allowFullScreen
          frameBorder={0}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: 700,
          }}
        />
      </div>
    </div>
  );
};

export default VideoGallery;
