import { Button } from "@material-ui/core";
import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import success from "../assets/success.json";
import Headernew from "./Headernew";
import { useParams } from "react-router-dom";
import Logo from "../assets/Logo.png";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const BookingSuccess = () => {
  const params = useParams();
  return (
    <div>
      <Headernew />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <div
          style={{
            height: 200,
            width: 200,
            borderRadius: "50%",
            backgroundColor: "white",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <img
            src={Logo}
            style={{
              height: "150px",
              objectFit: "cover",
              marginTop: -20,
            }}
            alt=""
          />
        </div>
      </div>

      <p
        style={{
          fontSize: 18,
          fontWeight: 550,
          textAlign: "center",
          padding: 8,
          letterSpacing: 1.5,
        }}
      >
        Thanks for submitting your details. One of our Live Sloka agent will
        contact you soon.
      </p>
      <p
        style={{
          fontSize: 18,
          fontWeight: 550,
          textAlign: "center",
          padding: 10,
          letterSpacing: 1.5,
          marginTop: 10,
        }}
      >
        Please use the below link to book a free demo
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to={`/booking/${params.id}`}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#3867d6", color: "white" }}
          >
            <p>Book Free Slot</p>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default BookingSuccess;
