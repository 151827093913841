import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Nav.css";
import Footer from "./Footer";
import SnackbarAlert from "../Components/SnackbarAlert";
import "react-phone-input-2/lib/material.css";
import "./TextInput.css";
import CareersForm from "./CareersForm";
import { Container } from "@material-ui/core";
import Headernew from "../Components/Headernew";

const useStyles = makeStyles((theme) => ({
  homeCard: {
    height: 100,
    width: 150,
    margin: 5,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },
  ImageCard: {
    height: "50%",
    width: "100%",
    objectFit: "cover",
  },
  CardChip: {
    background: "#0984e3 0% 0% no-repeat padding-box",
    borderRadius: "0px 50px 50px 0px",
    textAlign: "left",
    font: "normal normal bold 14px/21px Comfortaa",
    color: "#FFFFFF",
    opacity: 1,
  },
  formCont: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  formBg: {
    width: "50%",
    height: "90%",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textCont: {
    fontWeight: "bold",
    fontSize: 40,
    color: "white",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  highlightText: {
    fontSize: 45,
    color: "white",
    fontWeight: 600,
  },
  textFieldColor: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3867d6",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#3867d6",
    },
    "& .MuiInputLabel-outlined": {
      color: "black",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#3867d6",
    },
  },
  logoImg: {
    height: 100,
    width: 100,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logoText: {
    fontWeight: "bold",
    fontSize: 35,
    marginBottom: 5,
    color: "white",
    textShadow: "5px 5px 19px rgba(53, 53, 53, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));
const Careers = () => {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor] = useState("");
  const [alertMessage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Headernew />
      <div style={{ background: "#E3E7F0" }}>
        <Container>
          <div className={classes.formCont}>
            <div className={classes.formBg}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: 600,
                  marginTop: 20,
                  color: "#130f40",
                }}
              >
                We're Hiring!
              </p>

              <div style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}>
                <CareersForm />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <SnackbarAlert
        setOpen={setAlertOpen}
        open={alertOpen}
        message={alertMessage}
        color={alertColor}
      />
      <Footer />
    </>
  );
};

export default Careers;
