import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import OtpInput from "react-otp-input";
import Logo from "../assets/Logo.png";
import ReactGA from "react-ga";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useSnackbar } from "notistack";
import SnackbarAlert from "../Components/SnackbarAlert";
import api from "./../helpers/api";
import { Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
export default function OtpDialog({
  open,
  setOpen,
  validator,
  sendOtp,
  usersAge,
  userDetails,
  selectedTimezone,
  noRedirect,
  closeBooking,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [OTP, setOTP] = useState("");
  const history = useHistory();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertMessage, setalertMessage] = useState("");
  const [redirect, setRedirect] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);

    ReactGA.event({
      category: "OTP Dialog",
      action: "Closed OTP dialog",
      label: `${userDetails?.name} ${userDetails?.countryCode || "+1"} ${
        userDetails?.phone
      }`,
    });
  };

  const handleResendOTP = () => {
    sendOtp();
    setOTP("");
    setAlertOpen(true);
    setAlertColor("success");
    setalertMessage("OTP Sent Successfully");
    setSeconds(180);

    ReactGA.event({
      category: "OTP Dialog",
      action: "Clicked Resend OTP",
      label: `${userDetails?.name} ${userDetails?.countryCode || "+1"} ${
        userDetails?.phone
      }`,
    });
  };

  const handleOTPSubmit = async (otp) => {
    setLoading(true);
    try {
      let data = await api.get(`/register/validate/${validator}?otp=${otp}`);

      if (data.status === 200) {
        enqueueSnackbar(`Submitted Successfully!`, {
          variant: "success",
        });

        closeBooking();
        setOpen(false);
      }
      if (!noRedirect) {
        setRedirect(data.data.result);
      }
      localStorage.clear();
      localStorage.setItem("user", JSON.stringify(userDetails));
      ReactGA.event({
        category: "OTP Dialog",
        action: "Submitted OTP",
        label: `${userDetails?.name} ${userDetails?.countryCode || "+1"} ${
          userDetails?.phone
        }`,
      });
    } catch (error) {
      setAlertOpen(true);
      setAlertColor("error");
      setalertMessage("OTP expired or invalid");

      ReactGA.event({
        category: "OTP Dialog",
        action: "Invalid OTP",
        label: `${userDetails?.name} ${userDetails?.countryCode || "+1"} ${
          userDetails?.phone
        }`,
      });
    }
    setLoading(false);
  };

  const [seconds, setSeconds] = useState(180);

  useEffect(() => {
    if (seconds !== 0) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setSeconds(0);
      }
    }
  }, [seconds]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/booking/${redirect}`,
          state: { usersAge, selectedTimezone },
        }}
      />
    );
  }

  const handleOtpChange = (otp) => {
    setOTP(otp);

    if (otp.length === 4) {
      handleOTPSubmit(otp);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: 50 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={Logo}
              style={{
                height: 200,
                width: 200,
                marginTop: -50,
                objectFit: "contain",
                marginBottom: 20,
              }}
              alt=""
            />

            <p
              style={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}
            >
              {"Verification"}
            </p>

            <p style={{ textAlign: "center", fontSize: 18 }}>
              You will get a OTP via SMS
            </p>
          </div>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <OtpInput
                inputStyle={{
                  height: 40,
                  width: 40,
                  margin: 5,
                  outline: "none",
                  border: "1px solid #2d3436",
                  borderRadius: 10,
                }}
                value={OTP}
                isInputNum
                onChange={handleOtpChange}
                numInputs={4}
                separator={<span> - </span>}
              />
              {seconds > 0 ? (
                <p>
                  Your OTP expires in{" "}
                  {moment.utc(seconds * 1000).format("m:ss")}
                </p>
              ) : (
                <p style={{ textAlign: "center" }}>Your OTP expired</p>
              )}

              {loading && (
                <CircularProgress
                  style={{ height: 30, width: 30, marginTop: 20 }}
                />
              )}

              {seconds > 0 ? null : (
                <>
                  <p
                    style={{ textAlign: "center", fontSize: 18, marginTop: 10 }}
                  >
                    Didn't receive OTP?{" "}
                    <span
                      style={{ color: "#3867d6", fontWeight: "bold" }}
                      onClick={handleResendOTP}
                    >
                      Resend
                    </span>
                  </p>
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <SnackbarAlert
        setOpen={setAlertOpen}
        open={alertOpen}
        message={alertMessage}
        color={alertColor}
      />
    </div>
  );
}
