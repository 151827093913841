import React from 'react';

import Routes from './routes/Routes';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ChatHome from './ChatBot/ChatHome';
import './App.css';
import { CountryContextProvider } from './Components/CountryContext';
import { SnackbarProvider } from 'notistack';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ChatHome />
      <SnackbarProvider maxSnack={3}>
        <CountryContextProvider>
          <Routes />
        </CountryContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
