import { createContext } from 'react';
import useLocalStorage from './useLocalstorage';

export const CountryContext = createContext("");

export const CountryContextProvider = ({children}) => {
    const [country,setCountry] = useLocalStorage("country","");

    return (
        <CountryContext.Provider value={{country,setCountry}} >
            {children}
        </CountryContext.Provider>
    )
}

