import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import useWindowDimensions from "../helpers/useDimensions";
import TestimonialCard from "./TestimonialCard";
import Carousel from "react-material-ui-carousel";
import StudentReview from "./CustomerReview.json";
const useStyles = makeStyles((theme) => ({
  p: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 20,
    marginTop: 20,
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    border: "1px solid #2d3436",
  },
}));
const Testimonial = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div style={{ width: "95vw" }}>
      <div className={classes.textContainer}>
        <div>
          <p className={classes.p}>What our customers say</p>
        </div>
        <div></div>
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          marginLeft: width < 400 ? -15 : "",
          marginTop: -15,
        }}
      >
        {width > 600 ? (
          <Grid container>
            {StudentReview.map((item) => (
              <Grid item sm={12} sm={4}>
                <TestimonialCard item={item} desktop={true} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Carousel
            navButtonsProps={{
              style: {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            }}
            autoPlay={false}
            indicators={false}
            swipe={true}
            animation="slide"
          >
            {StudentReview.map((item) => (
              <TestimonialCard item={item} desktop={false} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default Testimonial;
