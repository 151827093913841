import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import api from "../helpers/api";
import Subjectcard from "./Subjectcard";
import { Grid, Button } from "@material-ui/core";
import useWindowDimensions from "../helpers/useDimensions";
import { CountryContext } from "./CountryContext";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div style={{ display: "flex" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function TabsComp() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { width } = useWindowDimensions();

  const [categorySubjects, setCategorySubjects] = useState([]);
  const { country } = useContext(CountryContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await api.get(`/admin/subject/${country}`);
        setCategorySubjects(data?.data.result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [country]);

  return (
    <div className={classes.root}>
      {width > 600 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            to="/booking"
          >
            <Button
              style={{
                width: "80%",
                height: "60px",
                backgroundColor: "#3867d6",
                color: "white",
              }}
            >
              Free Demo
            </Button>
          </Link>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            to="/booking"
          >
            <Button
              style={{
                width: "80%",
                backgroundColor: "#3867d6",
                color: "white",
              }}
            >
              Free Demo
            </Button>
          </Link>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: "#3867d6",
            },
          }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {categorySubjects &&
            categorySubjects.map((item, i) => (
              <Tab
                label={
                  <p style={{ textTransform: "none" }}>
                    {item.category.categoryName}
                  </p>
                }
                {...a11yProps(i)}
              />
            ))}
        </Tabs>
      </div>
      {categorySubjects &&
        categorySubjects.map((item, i) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TabPanel style={{ display: "flex" }} value={value} index={i}>
                {width > 600 ? (
                  <Grid
                    alignItems="stretch"
                    container
                    style={{ width: "100%" }}
                  >
                    {item.subjects.map((sub) => {
                      return (
                        <Grid
                          style={{ width: "100%", marginBottom: 10 }}
                          item
                          sm={12}
                          md={item.subjects.length > 2 ? 4 : 6}
                        >
                          <Link to="/booking">
                            <Subjectcard mobile={false} data={sub} />
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <>
                    {/* <Carousel
                      navButtonsProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                        },
                      }}
                      timeout={100}
                      autoPlay={false}
                      indicators={false}
                      swipe={true}
                      animation="slide"
                      navButtonsAlwaysVisible={
                        item.subjects.length > 1 ? true : false
                      }
                    >
                      {item.subjects.map((sub) => {
                        return <Subjectcard data={sub} />;
                      })}
                    </Carousel> */}
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.subjects.map((sub) => {
                        return (
                          <Grid
                            // style={{ width: "100%", marginBottom: 10 }}
                            item
                            md={2}
                          >
                            {" "}
                            <Link to="/booking">
                              <Subjectcard mobile={true} data={sub} />
                            </Link>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </TabPanel>
            </div>
          );
        })}
    </div>
  );
}
