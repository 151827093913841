import { Button, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import BookingImage from "../assets/booking1.png";
import RemoveIcon from "@material-ui/icons/Remove";
import { Form, Input, Radio } from "antd";
import PersonIcon from "@material-ui/icons/Person";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Components/booking-dialog.css";
import parsePhoneNumber from "libphonenumber-js";
import api from "../helpers/api";
import ReactGA from "react-ga";
import OtpDialog from "../Bookslot/OtpDialog";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../HomeLanding/Footer";
import Headernew from "../Components/Headernew";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { createJWT } from "../helpers";
import { useSnackbar } from "notistack";

export default function BookASlotNew({ open, setOpen }) {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      ReactGA.event({
        category: "Booking Modal",
        action: "Booking Modal Opened",
        label: `Booking modal opened on ${location.pathname}`,
      });
    }
  }, [open, location.pathname]);
  const history = useHistory();
  const [subjectValue, setSubjectValue] = useState("Sloka");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [validator, setValidator] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [formValues, setFormValues] = useState({});
  const [captchaToken, setCaptchaToken] = useState("");

  const handleClose = () => {
    ReactGA.event({
      category: "Booking Modal",
      action: "Booking Modal closed",
      label: `Booking modal closed on ${location.pathname}`,
    });

    setOpen(false);
  };
  const [form] = Form.useForm();
  let formRef = React.createRef();

  const onFinish = async (values, isResend) => {
    if (values.address) {
      return;
    }
    setLoading(true);
    const number = parsePhoneNumber(`+${values.number}`);
    const formData = {
      firstName: values.name,
      email: number?.nationalNumber,
      categoryText: subjectValue,
      countryCode: `+${number?.countryCallingCode}`,
      token: captchaToken,
    };

    try {
      let apiResponse = await Axios.get("https://ipapi.co/json");
      const { ip, country_name } = apiResponse?.data;
      let encryptedIpData = createJWT({ ip, country: country_name });

      let data = await api.post(`/register/customer`, formData, {
        headers: {
          Authorization: `Bearer ${encryptedIpData}`,
        },
      });

      setValidator(data.data.result);
      !isResend && setFormValues(values);
      // setOpenDialog(true);
      setUserDetails(formData);

      if (data.status === 200) {
        history.push(`/booking-success/${data?.data?.result}`);
        ReactGA.event({
          category: "Booking Modal",
          action: "Demo booked successfully",
          label: `${values.name} ${number?.countryCallingCode || "+1"} ${
            number?.nationalNumber
          }`,
        });
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error",
      });

      ReactGA.event({
        category: "Booking Modal",
        action: "Demo booked failed",
        label: `${values.name} ${number?.countryCallingCode || "+1"} ${
          number?.nationalNumber
        }`,
      });
    }
    setLoading(false);
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div>
      {openDialog && (
        <OtpDialog
          userDetails={userDetails}
          sendOtp={() => {
            onFinish(formValues, true);
          }}
          validator={validator}
          open={openDialog}
          setOpen={setOpenDialog}
          noRedirect={true}
          closeBooking={handleClose}
        />
      )}

      <div style={{ marginBottom: 20 }}>
        <Headernew />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
          flexDirection: "column",
          minHeight: "auto",
          maxWidth: "500px",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: 10,
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          marginBottom: 20,
          padding: 20,
          position: "relative",
        }}
      >
        <div className="ribbon ribbon-top-left">
          <span>
            <p style={{ fontSize: 12 }}>10% off on all classes</p>
          </span>
        </div>
        <div
          style={{
            height: 40,
            width: "95%",
            backgroundColor: "#670016",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <p style={{ color: "white", fontWeight: "bold" }}>
            Learn Indian Dance and Music
          </p>
        </div>
        <img style={{ width: "95%" }} src={BookingImage} alt="" srcset="" />

        <p style={{ textAlign: "center", fontWeight: 500, marginTop: 15 }}>
          Teach your kid about Indian culture through Dance and Music
        </p>

        <p
          style={{
            textAlign: "center",
            fontWeight: 550,
            marginTop: 15,
            color: "#34000C",
          }}
        >
          <RemoveIcon style={{ marginBottom: -8, marginRight: 5 }} />
          At the comfort of your home
          <RemoveIcon style={{ marginBottom: -8, marginLeft: 5 }} />
        </p>

        <div
          className="booking-form-input"
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            ref={formRef}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", left: 5, zIndex: 5, top: 0 }}>
                <div
                  style={{
                    height: 50,
                    width: 48,
                    backgroundColor: "#670016",
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ color: "white" }} />
                </div>
              </div>
              <Form.Item
                name="name"
                type="text"
                fullWidth
                style={{ width: "95%" }}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Full Name",
                  },
                ]}
              >
                <Input
                  style={{
                    height: 50,
                    borderTopRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    paddingLeft: 50,
                  }}
                  placeholder="Enter Your Full Name"
                />
              </Form.Item>
            </div>
            <Form.Item name="address" style={{display: "none"}} type="text" fullWidth>
              <Input placeholder="Enter Your Full address" />
            </Form.Item>
            <div>
              <Form.Item
                style={{
                  marginTop: 20,
                  marginLeft: 5,
                }}
                name="number"
                fullWidth
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Please input your Number",
                  },
                ]}
              >
                <PhoneInput
                  onlyCountries={["us", "gb", "sg", "au", "in"]}
                  country={"us"}
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <Radio.Group
                value={subjectValue}
                onChange={(e) => setSubjectValue(e.target.value)}
              >
                <Radio value={"Sloka"}>Sloka</Radio>
                <Radio value={"Dance"}>Dance</Radio>
                <Radio value={"Music"}>Music</Radio>
                <Radio value={"Chess"}>Chess</Radio>
                <Radio value={"Piano"}>Piano</Radio>
              </Radio.Group>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                onChange={handleCaptchaChange}
              />
            </div>

            <Button
              type="submit"
              disabled={loading || !captchaToken}
              fullWidth
              style={{
                marginTop: 30,
                backgroundColor: loading || !captchaToken ? "#aaa" : "#34000C",
                color: "white",
                height: 50,
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "BOOK A FREE DEMO"
              )}
            </Button>
          </Form>
        </div>
      </div> */}
      <div style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      }}>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc6yI3dhfOFg1qqEXrw7WnDrca9MWpcQ1StqQQ1_h5IVt_47Q/viewform?embedded=true" width="640" height="1497" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
      <Footer />
    </div>
  );
}
