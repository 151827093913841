import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { IconButton } from "@material-ui/core";
import { Menu, Video } from "react-feather";
import { Link } from "react-router-dom";
import { Home, Info, Calendar, Users, Briefcase } from "react-feather";
import Logo from "../assets/Logo.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function Sidebarnav() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const menuLinks = [
    {
      id: 1,
      title: "Home",
      icon: <Home />,
      link: "/",
      color: "#ff6348",
    },
    {
      id: 2,
      title: "About",
      icon: <Info />,
      link: "/about",
      color: "#3867d6",
    },
    {
      id: 3,
      title: "Book A Demo",
      icon: <Calendar />,
      link: "/booking",
      color: "#26de81",
    },
    {
      id: 4,
      title: "Teachers",
      icon: <Users />,
      link: "/teachers",
      color: "#be2edd",
    },
    {
      id: 5,
      title: "Careers",
      icon: <Briefcase />,
      link: "/apply",
      color: "#2f3542",
    },
    {
      id: 6,
      title: "Gallery",
      icon: <Video />,
      link: "/gallery",
      color: "#3867d6",
    },
  ];
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 200,
            width: 200,
            borderRadius: "50%",
            backgroundColor: "white",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <img
            src={Logo}
            style={{
              height: "150px",
              objectFit: "cover",
              marginTop: -20,
            }}
            alt=""
          />
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <List>
          {menuLinks.map((item) => {
            return (
              <Link to={item.link}>
                <ListItem button key={item.id}>
                  <ListItemIcon style={{ color: item.color }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <p style={{ color: "black", fontWeight: "bold" }}>
                        {item.title}
                      </p>
                    }
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <IconButton onClick={toggleDrawer("left", true)}>
          <Menu style={{ color: "white" }} />
        </IconButton>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
