import { Container } from "@material-ui/core";
import React, { useEffect } from "react";

import styles from "./styles.module.css";
import { Phone, Menu } from "react-feather";
import getWindowDimensions from "../helpers/useDimensions";
import Logo from "../assets/livesloka_logo.png";
import { makeStyles } from "@material-ui/core/styles";
import "./Nav.css";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import Footer from "./Footer";
import Header from "./Header";
import Headernew from "../Components/Headernew";

let ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  homeCard: {
    height: 100,
    width: 150,
    margin: 5,
  },
  ImageCard: {
    height: "50%",
    width: "100%",
    objectFit: "cover",
  },
  CardChip: {
    background: "#0984e3 0% 0% no-repeat padding-box",
    borderRadius: "0px 50px 50px 0px",
    textAlign: "left",
    font: "normal normal bold 14px/21px Comfortaa",
    color: "#FFFFFF",
    opacity: 1,
  },
  formCont: {
    // minHeight: "100vh",
    // display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  formBg: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ecf0f1",
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  aboutText: {
    lineHeight: 2,
    paddingTop: 20,
  },

  logoText: {
    fontWeight: "bold",
    fontSize: 35,
    marginBottom: 5,
    color: "white",
    textShadow: "5px 5px 19px rgba(53, 53, 53, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));
const Aboutus = () => {
  const { width } = getWindowDimensions();

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <div className={styles.container}> */}
      {/* <div className={styles.overlay}> */}
      {/* <Header /> */}
      <Headernew />

      <Container>
        <div className={classes.formCont}>
          <div className={classes.formBg}>
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  fontWeight: "bold",
                  color: "#273c75",
                  paddingTop: 20,
                  paddigBottom: 10,
                }}
              >
                About Live Sloka
              </p>

              <Container
                style={{ paddingTop: 10, width: "90%", paddingBottom: 20 }}
              >
                <p className={classes.aboutText}>
                  Live Sloka is an online learning platform to enable everyone
                  to learn Indian classical art forms. There is a certain grace,
                  rhythm, and innate beauty in Indian Cultural heritage. Live
                  Sloka is an initiative to bring the experience of learning
                  these beautiful art forms for kids and adults.
                </p>

                <p className={classes.aboutText}>
                  Our journey started with enabling kids and adults to learn
                  sacred slokas with perfect pronunciation and intonation that
                  are chanted in homes and temples across India. Learning these
                  sacred chants enables children to enhance their memory and
                  pronunciation. We went on to introduce Carnatic Music, which
                  was as beautiful an experience as learning slokas was. To get
                  in tune with the Indian Classical Music forms is an immersive
                  experience by itself.
                </p>

                <p className={classes.aboutText}>
                  Further, we have launched Indian Classical dance forms. When
                  head, heart, and hands come together in unison to express the
                  divine, art finds its completeness. The more we get in touch
                  with the rich cultural heritage of India, the more we explore
                  the full potential within us.
                </p>

                <p className={classes.aboutText}>
                  We hope that you would join us on this beautiful journey to
                  experience the joy of learning the sacred Sanskrit Slokas,
                  Indian Classical Music as well as Indian Classical Dance.
                </p>

                <p className={classes.aboutText}>
                  We bring home this opportunity to learn slokas, music, and
                  dance online from dedicated and experienced teachers from
                  prestigious institutions of India.
                </p>
              </Container>
            </div>
          </div>
        </div>
      </Container>
      {/* </div> */}
      {/* </div> */}

      <Footer />
    </>
  );
};

export default Aboutus;
