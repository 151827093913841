import React, { useContext, useEffect } from "react";
import { Router } from "react-router";
import { Route, Switch } from "react-router-dom";
import BookSlotNew from "../Bookslot/BookSlotNew";
import Aboutus from "../HomeLanding/Aboutus";
import AllTeachers from "../HomeLanding/AllTeachers";
import Booking from "../HomeLanding/Booking";
import Careers from "../HomeLanding/Careers";
import CookiePolicy from "../HomeLanding/CompanyPages/CookiePolicy";
import PrivacyPolicy from "../HomeLanding/CompanyPages/PrivacyPolicy";
import RefundPolicy from "../HomeLanding/CompanyPages/RefundPolicy";
import Tos from "../HomeLanding/CompanyPages/Tos";
import Subject from "../HomeLanding/Subject";
import Home from "../pages/Home";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import VideoGallery from "../pages/VideoGallery";
import MoreInfo from "../pages/MoreInfo";
import { CountryContext } from "./../Components/CountryContext";
import axios from "axios";
import BookingSuccess from "../Components/BookingSuccess";
import BookASlotNew from "../Bookslot/BookASlotNew";
import { MemoryRouter } from "react-router";
export const history = createBrowserHistory();

ReactGA.initialize(process.env.REACT_APP_API_REACT_GA);

const Routes = () => {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  });

  const { setCountry, country } = useContext(CountryContext);
  useEffect(() => {
    if (!country) {
      axios.get("https://ipapi.co/json/").then((data) => {
        setCountry(data?.data?.country_name);
      });
    }
  }, [country, setCountry]);

  return (
    <Router history={history}>
      {/* <MemoryRouter> */}
      <Switch>
        {/* <Route path="/" exact component={HomeLanding} /> */}
        <Route path="/" exact component={Home} />
        <Route path="/country/:country" exact component={Home} />
        <Route path="/contact" exact component={Booking} />
        <Route path="/bookAdemo" exact component={BookASlotNew} />
        <Route path="/booking" exact component={BookASlotNew} />
        <Route path="/booking/:id" exact component={BookSlotNew} />
        <Route path="/apply" exact component={Careers} />
        <Route path="/about" exact component={Aboutus} />
        <Route path="/teachers" exact component={AllTeachers} />
        <Route path="/subject/:id/:name" exact component={Subject} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/tos" exact component={Tos} />
        <Route path="/refund" exact component={RefundPolicy} />
        <Route path="/cookie" exact component={CookiePolicy} />
        <Route path="/gallery" exact component={VideoGallery} />
        <Route path="/moreinfo/:id" exact component={MoreInfo} />
        <Route path="/booking-success/:id" exact component={BookingSuccess} />
      </Switch>
      {/* </MemoryRouter> */}
    </Router>
  );
};

export default Routes;
