import React, { useEffect, useState } from 'react';

import ChatBot from './ChatBot';
import BotButton from './BotButton';
import axios from 'axios';
import { io } from 'socket.io-client';
import url from './coins.mp3';
var audio = new Audio(url);

let socket;
const ChatHome = () => {
  const [bot, setbot] = useState(false);
  const [show, setShow] = useState(false);
  const [isBot, setIsBot] = useState(true);

  const [roomID, setroomID] = useState(localStorage.getItem('ROOMID') || null);

  const [alert, setAlert] = useState(0);
  const showBot = (isActive) => {
    setbot(isActive);
    if (!!alert) {
      setAlert(0);
    }
  };
  const [country, setCountry] = useState('');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY_CRM}/getNonChatConfig`)
      .then(({ data }) => {
        const { show, time, bot } = data[0];
        if (show === true) {
          setShow(show);
          setIsBot(bot);
          setTimeout(() => {
            console.log('Open Bot');
            setAlert(2);
            audio.play();
          }, time * 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    socket = io.connect(`${process.env.REACT_APP_API_KEY_CRM}/`);
    socket.on('toggleNonChatBot', ({ show }) => {
      setShow(show);
      setAlert(2);
      audio.play();
    });
    axios.get('https://ipapi.co/json/').then(({ data }) => {
      const { city, country, region, ip, country_name } = data;
      setCountry({ city, country, region, ip, country_name });
    });

    return removeListners;
  }, []);

  const removeListners = () => {
    console.log('removing from App');
    socket.removeAllListeners();
  };

  useEffect(() => {
    if (roomID) {
      socket.emit('JOIN_NONROOM', { roomID, isAdmin: false }, (error) => {
        if (error) alert(error);
      });
      socket.on('messageToNonRoomFromAdmin', ({ role, message, username }) => {
        console.log(message);
        setAlert((count) => count + 1);
        audio.play();
      });
    }
    console.log('useEffect Ran ', roomID);
  }, [roomID]);

  return (
    <>
      {show ? (
        bot ? (
          <ChatBot
            showBot={showBot}
            setID={setroomID}
            isBot={isBot}
            setIsBot={setIsBot}
            country={country}
          />
        ) : (
          <BotButton showBot={showBot} alert={alert} />
        )
      ) : null}
    </>
  );
};

export default ChatHome;
