import { Button } from "@material-ui/core";
import { Card, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../helpers/useDimensions";
const Subjectcard = ({ data, mobile }) => {
  const { width } = useWindowDimensions();
  const useStyles = makeStyles((theme) => ({
    card: {
      height: mobile ? (width <= 360 ? 170 : 200) : "100%",
      width: mobile
        ? width <= 360
          ? 120
          : 142
        : width > 600
        ? 350
        : width < 400
        ? 320
        : 320,
      margin: 10,
      border: "2px solid #bdc3c7",
      position: "relative",
    },
    img: {
      height: mobile ? (width <= 360 ? 120 : 150) : "auto",
      width: mobile ? (width <= 360 ? 120 : 150) : "100%",
      objectFit: "cover",
      boxShadow: "0px 3px 5px 0px rgba(165,164,164,0.75)",
    },
    btn: {
      height: 40,
      width: 130,
    },
    borderbtn: {
      border: "2px solid #3867d6",
      color: "#3867d6",
      fontWeight: "bold",
    },
    bgBtn: {
      backgroundColor: "#3867d6",
      color: "white",
      width: "80%",
      position: "absolute",
      bottom: mobile ? 5 : 10,
      left: "50%",
      transform: "translate(-50%)",
      "&:hover": {
        backgroundColor: "#3867d6",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Card className={classes.card}>
        <div>
          <img
            className={classes.img}
            src={`/assets/${data.subjectDesc}.jpeg`}
            alt=""
          />
        </div>

        <div style={{ padding: mobile ? 0 : 20 }}>
          <p
            style={{
              fontWeight: "bold",
              textAlign: mobile ? "center" : "",
              marginTop: mobile ? 10 : 0,
            }}
          >
            {data && data.subjectName}
          </p>

          {mobile ? null : <p>{data.description}</p>}

          {/* {mobile ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                width: "100%",
              }}
            >
              <Link style={{ width: "80%", paddingTop: 20 }} to="/booking">
                <Button className={[classes.btn, classes.bgBtn]}> Free Demo </Button>
              </Link>
            </div>
          )} */}
        </div>
      </Card>
    </div>
  );
};

export default Subjectcard;
