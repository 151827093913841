import React, { useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Close from '@material-ui/icons/Close';
import { Button, IconButton, TextField } from '@material-ui/core';
import { Send, User } from 'react-feather';
import moment from 'moment';
let socket;
const BOT_NAME = 'Kuhu';
const ChatBot = ({ showBot, setID, isBot, setIsBot, country }) => {
  const lastElement = useRef(null);
  const [timeout, setSTimeout] = useState(undefined);

  const usernameInp = useRef();

  const [roomID, setroomID] = useState(localStorage.getItem('ROOMID'));
  const [username, setUsername] = useState(localStorage.getItem('USERNAME'));
  const [message, setmessage] = useState('');
  const [messages, setmessages] = useState([]);

  console.log(country);
  const [isTyping, setIsTyping] = useState(false);
  // const [isBot, setIsBot] = useState(isBotv);
  const [canEdit, setCanEdit] = useState(
    !!localStorage.getItem('ROOMID') && !!localStorage.getItem('USERNAME')
  );

  const createRoom = (username) => {
    console.log('in create room');
    if (!roomID) {
      console.log('in create room inside');
      const id = uuidv4();
      socket.emit(
        'create-nonroom',
        {
          roomID: id,
          username,
          country,
        },
        (error) => {
          if (error) {
            alert(JSON.stringify(error));
          } else {
            setroomID(id);
            setID(id);
            localStorage.setItem('ROOMID', id);
            setCanEdit(true);
            console.log(messages);
            setmessages([
              {
                role: 3,
                message:
                  "Hi there 🖐. I'm here to help with your general enquiries.",
                username: BOT_NAME,
              },
              {
                role: 3,
                message: `Hi ${username} 🖐. How Can I help You?.`,
                username: BOT_NAME,
                createdAt: new Date().toISOString(),
              },
            ]);
          }
        }
      );
    }
  };

  // const notifyRoom = (roomID, type, msgs) => {
  //   socket.emit('notifyToNonRoomFromNonUser', { roomID, username }, (error) => {
  //     if (error) {
  //       alert(JSON.stringify(error));
  //     } else {
  //       //   const questionMessages = [
  //       //     ...msgs,
  //       //     {
  //       //       role: 0,
  //       //       message: typeMessage,
  //       //       username: BOT_NAME,
  //       //     },
  //       //   ];
  //       //   setmessages(questionMessages);
  //       setCanEdit(true);
  //     }
  //   });
  // };

  const scrollToBottom = () => {
    lastElement.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  useEffect(() => {
    socket = io.connect(`${process.env.REACT_APP_API_KEY_CRM}/`);
    if (roomID) {
      axios
        .get(`${process.env.REACT_APP_API_KEY_CRM}/nonroom/${roomID}`)
        .then(({ data }) => {
          console.log('room Present');
          socket.emit('JOIN_NONROOM', { roomID, isAdmin: false }, (error) => {
            if (error) alert(error);
          });

          const allMsgs = data.messages;
          if (allMsgs.length <= 0) {
            let filterdMsgs = [
              ...allMsgs,
              {
                role: 3,
                message: `Welcome Back ${username} 🖐. I'm here to help with your general enquiries.`,
                username: BOT_NAME,
                createdAt: new Date().toISOString(),
              },
            ];
            setmessages(filterdMsgs);
          } else {
            setmessages(allMsgs);
          }
        })
        .catch((err) => {
          console.log(err.response);
          localStorage.removeItem('ROOMID');
          localStorage.removeItem('USERNAME');
          showBot(false);
        });
    } else {
      setmessages([
        {
          role: 3,
          message: "Hi there 🖐. I'm here to help with your general enquiries.",
          username: BOT_NAME,
        },
        {
          role: 3,
          message: (
            <>
              <div className="username-main">
                <TextField
                  id="username-inp"
                  label="Enter Your Name"
                  size="small"
                  inputRef={usernameInp}
                />
                <div className="username-btns">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className="username-btn"
                    onClick={handelUsername}
                  >
                    OK
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className="username-btn"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          ),
          username: BOT_NAME,
          isCustom: true,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    socket.on('messageToNonRoomFromAdmin', ({ role, message, username }) => {
      setIsTyping(false);
      setmessages((prevState) => {
        const copyState = [...prevState];
        copyState.push({ role, message, username });
        return copyState;
      });
    });
    socket.on('non-agent-typing', ({ username, typing }) => {
      setIsTyping(typing);
    });

    return removeListners;
  }, []);
  const removeListners = () => {
    console.log('removing');
    socket.removeAllListeners();
  };
  const handelUsername = () => {
    const usr = usernameInp.current.value.trim();
    if (usr) {
      console.log(usr);
      localStorage.setItem('USERNAME', usr);
      createRoom(usr);
      setUsername(usr);
      usernameInp.current.value = '';
    }
  };

  const typingTimeout = (msg) => {
    console.log('stoped Typing');
    socket.emit(
      'non-user-typing',
      { roomID, username, typing: false, message: msg },
      (error) => {
        if (error) {
          alert(JSON.stringify(error));
        }
      }
    );
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    if (message.trim() === '') {
      return;
    }

    if (!roomID) {
      console.log('No ROOM TO SEND MESSAGE');
      // createRoom(message);
    } else {
      sendMessageToNonRoom();
    }
  };

  const sendMessageToNonRoom = () => {
    console.log('Sending Msg');
    socket.emit(
      'messageFromNonBot',
      { roomID, message, username, isBot },
      ({ error, data }) => {
        if (error) {
          alert(JSON.stringify(error));
        }

        if (isBot && data) {
          botResponse(data);
        }
      }
    );
    setmessages((prevState) => {
      const copyState = [...prevState];
      copyState.push({ role: 0, message });
      return copyState;
    });
    setmessage('');
  };

  const handelChange = (e) => {
    let ftime = undefined;
    // console.log(e.target.value);
    socket.emit(
      'non-user-typing',
      { roomID, username, typing: true, message: e.target.value },
      (error) => {
        if (error) {
          alert(JSON.stringify(error));
        }
      }
    );
    clearTimeout(timeout);
    ftime = setTimeout(() => typingTimeout(e.target.value), 2000);
    setSTimeout(ftime);

    setmessage(e.target.value);
  };

  const botResponse = ({ response, fallback, online }) => {
    console.log(online);
    if (!fallback) {
      setmessages((prevState) => {
        const copyState = [...prevState];
        copyState.push({ role: 3, message: response, username: BOT_NAME });
        return copyState;
      });
    } else if (online) {
      setmessages((prevState) => {
        const copyState = [
          ...prevState,
          {
            role: 3,
            message: response,
            username: BOT_NAME,
          },
          {
            role: 3,

            message:
              'All Our Agents are Offline. Please ping us On WhatsApp by clicking on the Icon.',

            username: BOT_NAME,
          },
          {
            role: 3,
            isCustom: true,
            message: (
              <p>
                <a
                  target="__blank"
                  href="https://api.whatsapp.com/send?phone=+918309341208"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    style={{ fill: '#000000' }}
                  >
                    <path
                      fill="#fff"
                      d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                    ></path>
                    <path
                      fill="#cfd8dc"
                      d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                    ></path>
                    <path
                      fill="#40c351"
                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                    ></path>
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </p>
            ),
            username: BOT_NAME,
          },
        ];

        return copyState;
      });
    } else {
      setmessages((prevState) => {
        const copyState = [
          ...prevState,
          {
            role: 3,
            message: response,
            username: BOT_NAME,
          },
          {
            role: 3,
            message: (
              <>
                <div className="username-main">
                  <p>Do you want to connect with agent?</p>
                  <div className="username-btns">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      className="username-btn"
                      onClick={() => {
                        agentNeeded(true);
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => {
                        agentNeeded(false);
                      }}
                      className="username-btn"
                    >
                      No
                    </Button>
                  </div>
                </div>
              </>
            ),
            username: BOT_NAME,
            isCustom: true,
          },
        ];

        return copyState;
      });
    }
  };

  const agentNeeded = (value) => {
    if (value) {
      setmessages((prevState) => {
        const copyState = [...prevState];
        copyState.pop();
        copyState.push({
          role: 3,
          message: 'Connecting With a agent Please Wait',
          username: BOT_NAME,
        });

        return copyState;
      });
      setIsBot(false);

      socket.emit('agent-needed-nonchat', {
        username,
        roomID,
      });
    } else {
      setmessages((prevState) => {
        const copyState = [...prevState];
        copyState.pop();
        copyState.push({
          role: 3,
          isCustom: true,
          message: (
            <>
              <div className="custom-reply-bot">
                <p>
                  <span>Continue chat with us on whatsapp</span>
                  <a
                    style={{ marginLeft: '10px' }}
                    target="__blank"
                    href="https://api.whatsapp.com/send?phone=+918309341208"
                  >
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      title="Message Live Sloka"
                    >
                      <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                    </svg>
                  </a>
                </p>
              </div>
            </>
          ),
          username: BOT_NAME,
        });
        return copyState;
      });
    }
  };
  return (
    <>
      <div
        className="chatbot"
        id="chatbot"
        style={{
          backgroundColor: '#fff',
        }}
      >
        <div className="chathome__header">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItem: 'center',
              marginLeft: '20px',
            }}
          >
            <h3 style={{ marginBottom: '0px' }}>Live Sloka</h3>
            <p>
              <a
                style={{
                  marginLeft: '10px',
                  paddingTop: '5px',
                  color: 'white',
                }}
                target="__blank"
                href="https://api.whatsapp.com/send?phone=+918309341208"
              >
                Click here to chat with us on WhatsApp.
              </a>
            </p>
          </div>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              showBot(false);
            }}
          >
            <Close
              style={{
                color: '#fff',
              }}
            />
          </IconButton>
        </div>
        <div className="chatbot__message-window">
          <ul className="chatbot__messages" id="chatbot__messages">
            {messages.map(
              ({ role, message, username, isCustom, createdAt }, idx) => {
                if (role !== 0) {
                  return (
                    <li className="is-ai animation" key={idx}>
                      <div className="is-ai__profile-picture" title={username}>
                        <User style={{ color: '#e67e22' }} />
                      </div>
                      <span className="chatbot__arrow chatbot__arrow--left"></span>
                      <div
                        className="chatbot__message"
                        style={{ paddingTop: 0 }}
                      >
                        {/* <p className="chatbot__chatname">{username}</p> */}
                        <span className="chatbot__chatname">{username}</span>

                        {isCustom ? message : <p>{message}</p>}
                        <span className="chat--time">
                          {/* {new Date(createdAt).toLocaleString()} */}
                          {moment(createdAt).format('LT')}
                        </span>
                      </div>
                      {/* //changed to div from p */}
                    </li>
                  );
                } else {
                  return (
                    <li className="is-user animation" key={idx}>
                      <p className="chatbot__message">{message}</p>
                      <span className="chat--time">
                        {moment(createdAt).format('LT')}
                      </span>
                      {/* <span className="chatbot__arrow chatbot__arrow--right"></span> */}
                    </li>
                  );
                }
              }
            )}
            {isTyping && (
              <li className="is-ai animation">
                <div className="is-ai__profile-picture">
                  <User style={{ color: '#e67e22' }} />
                </div>
                <span className="chatbot__arrow chatbot__arrow--left"></span>
                <p className="chatbot__message">
                  <div className="typing">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </p>
              </li>
            )}
          </ul>
          <div ref={lastElement}></div>
        </div>

        <form
          className="chatbot__entry chatbot--closed"
          onSubmit={handelSubmit}
        >
          <input
            type="text"
            className="chatbot__input"
            placeholder="Write a message..."
            value={message}
            onChange={handelChange}
            disabled={!canEdit}
            style={{
              cursor: canEdit ? 'text' : 'not-allowed',
            }}
          />
          <IconButton type="submit">
            <Send style={{ color: '#e67e22' }} />
          </IconButton>
        </form>
      </div>
    </>
  );
};

export default ChatBot;
