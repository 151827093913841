import { makeStyles } from "@material-ui/core";
import React from "react";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import useWindowDimensions from "../helpers/useDimensions";
import { teachersData } from "../HomeLanding/teachersData";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const useStyles = makeStyles((theme) => ({
  p: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 20,
    marginTop: 20,
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    border: "1px solid #2d3436",
  },

  teacherContainer: {
    height: 280,
    width: "90%",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: 10,
    padding: 30,
    margin: 30,
  },
}));

const Card = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.teacherContainer}>
      <div>
        <FormatQuoteIcon style={{ color: "#f1c40f" }} />
        <p>{item?.more}</p>
      </div>
      <hr style={{ marginTop: 25, marginBottom: 25 }} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          style={{
            height: 100,
            width: 100,
            objectFit: "contain",
            borderRadius: 100 / 2,
          }}
          src={item?.image}
          alt=""
        />

        <div style={{ marginLeft: 20 }}>
          <p style={{ fontSize: 20, fontWeight: 600 }}>{item?.name}</p>
          <p style={{ fontSize: 12, fontWeight: 600 }}>{item?.role}</p>
          <p style={{ fontSize: 12, fontWeight: 600 }}>{item.desc}</p>
        </div>
      </div>
    </div>
  );
};
const AboutTeacher = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const items = [];
  teachersData.map((item) => {
    let card = <Card item={item} />;

    items.push(card);
  });
  return (
    <div style={{ width: "95vw" }}>
      <div className={classes.textContainer}>
        <div>
          <p className={classes.p}>Our Teachers</p>
        </div>
        <div></div>
      </div>

      <div
        style={{
          display: "flex",
          marginLeft: width < 400 ? -15 : "",
          marginTop: -15,
        }}
      >
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate"
        />
      </div>
    </div>
  );
};

export default AboutTeacher;
