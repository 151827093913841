import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Headernew from "../Components/Headernew";
import MaleIcon from "../assets/male.png";
import FemaleIcon from "../assets/woman.png";
import { Form, Input } from "antd";
import { useLocation, useParams } from "react-router-dom";
import api from "../helpers/api";
import { User } from "react-feather";
import "./style.css";
import Logo from "../assets/Logo.png";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "auto",
  },

  customChip: {
    height: 40,
    width: "fit-content",
    border: "1.8px solid #bdc3c7",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
  },

  cardContainer: {
    marginTop: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    borderRadius: 10,
    width: "367px",
    paddingLeft: 10,
    paddingTop: 10,
  },

  maleActivatedChip: {
    height: 40,
    width: "fit-content",
    border: "1.55px solid #f0932b",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
    backgroundColor: "#ffbe764a",
  },
  ActivatedavatarcustomChip: {
    backgroundColor: "#2ecc714a",
  },
}));

const gender = ["male", "female"];

const MoreInfo = () => {
  const classes = useStyles();
  const [activatedGender, setActivatedGender] = useState("male");

  const params = useParams();
  const { state } = useLocation();
  const submitForm = async (values) => {
    try {
      const formData = {
        lastName: values.parentName,
        age: values.age,
        gender: activatedGender,
      };
      await api.put(`/register/${params.id}`, formData);
      window.location = "https://mylivesloka.com";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Headernew />
        <div className={classes.container}>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <img
                    src={Logo}
                    style={{
                      height: "150px",
                      objectFit: "cover",
                      marginTop: -20,
                    }}
                    alt=""
                  />
                </div>
              </div>
              {/* <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Registration successful
              </p> */}

              <p style={{ textAlign: "center", fontSize: 18, marginTop: 10 }}>
                Thank you for booking a demo with us successfully. Please login
                with below credentials to{" "}
                <a href="https://mylivesloka.com">mylivesloka.com</a>
              </p>

              <div className="cred" style={{ marginTop: 10 }}>
                <span
                  style={{ textAlign: "center", fontSize: 20, marginRight: 10 }}
                >
                  User Id:
                </span>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {state.userId}
                </span>
              </div>
              <div className="cred">
                <span
                  style={{ textAlign: "center", fontSize: 20, marginRight: 10 }}
                >
                  Password
                </span>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  livesloka
                </span>
              </div>
            </div>
            <div className={classes.cardContainer}>
              <h5 style={{ textAlign: "center" }}>
                {" "}
                Kindly give us additional information about the student
                attending the demo{" "}
              </h5>
              <Form
                onFinish={submitForm}
                style={{ width: "100%", paddingBottom: 20 }}
              >
                <div className={classes.preferredClass}>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Student age
                  </p>
                  <Form.Item
                    name="age"
                    style={{ width: "95%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Age",
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: "Value should contain just number",
                      },
                      {
                        pattern: /^[\d]{0,2}$/,
                        message: "Invalid age",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>

                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Parent Name
                  </p>
                  <Form.Item
                    name="parentName"
                    style={{ width: "95%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Parent name",
                      },
                      {
                        pattern: /^[a-zA-Z ]{2,30}$/,
                        message: "Invalid Name",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className={classes.preferredClass}>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <User style={{ height: 20, widht: 20, marginRight: 5 }} />{" "}
                    Gender
                  </p>
                  <div style={{ display: "flex" }}>
                    {gender &&
                      gender.map((gender) => (
                        <div
                          key={gender}
                          onClick={() => {
                            setActivatedGender(gender);
                          }}
                          className={
                            activatedGender === gender
                              ? classes.maleActivatedChip
                              : classes.customChip
                          }
                          style={{ width: 70 }}
                        >
                          {gender === "male" ? (
                            <img
                              src={MaleIcon}
                              style={{ height: 25, width: 25 }}
                              alt="Male"
                            />
                          ) : (
                            <img
                              src={FemaleIcon}
                              style={{ height: 25, width: 25 }}
                              alt="Female"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Button
                    type="submit"
                    className="booking-button"
                    variant="contained"
                    color="primary"
                  >
                    Update Details
                  </Button>
                  <Button
                    className="booking-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.location = "https://mylivesloka.com";
                    }}
                  >
                    Skip to Login
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MoreInfo;
