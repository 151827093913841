import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { makeStyles } from "@material-ui/core/styles";
import "./Nav.css";
import api from "../helpers/api";
import Footer from "./Footer";
import SnackbarAlert from "../Components/SnackbarAlert";
import Header from "./Header";
import "./TextInput.css";
import AntForm from "./AntForm";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  homeCard: {
    height: 100,
    width: 150,
    margin: 5,
  },
  ImageCard: {
    height: "50%",
    width: "100%",
    objectFit: "cover",
  },
  CardChip: {
    background: "#0984e3 0% 0% no-repeat padding-box",
    borderRadius: "0px 50px 50px 0px",
    textAlign: "left",
    font: "normal normal bold 14px/21px Comfortaa",
    color: "#FFFFFF",
    opacity: 1,
  },

  formCont: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  formBg: {
    width: "70%",
    height: "90%",
    backgroundColor: "rgba(236, 240, 241, 0.9)",
    borderRadius: 10,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textCont: {
    position: "relative",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  textCon: {
    fontWeight: "bold",
    fontSize: 40,
    color: "white",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  highlightText: {
    fontSize: 45,
    color: "white",
    fontWeight: 600,
  },

  textFieldColor: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3867d6",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#3867d6",
    },
    "& .MuiInputLabel-outlined": {
      color: "black",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#3867d6",
    },
  },
  logoImg: {
    height: 100,
    width: 100,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logoText: {
    fontWeight: "bold",
    fontSize: 35,
    marginBottom: 5,
    color: "white",
    textShadow: "5px 5px 19px rgba(53, 53, 53, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));
const Booking = () => {
  const classes = useStyles();
  const history = useHistory();
  const [timeZones, settimeZones] = useState();
  const [getSubjects, setGetSubjects] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertMessage, setalertMessage] = useState("");

  useEffect(() => {
    history.push("/booking");
    fetchTimeZones();
    fetchSubjects();
  }, []);
  const fetchTimeZones = async () => {
    const data = await api.get("/admin/get/timeZone");
    settimeZones(data?.data.result);
  };

  const fetchSubjects = async () => {
    const data = await api.get("/admin/get/Subject");
    setGetSubjects(data?.data.result);
  };

  const getbackData = (data) => {
    if (data?.status === 200) {
      setAlertOpen(true);
      setAlertColor("success");
      setalertMessage(data?.data?.message);
    } else if (data?.status === 400) {
      setAlertOpen(true);
      setAlertColor("error");
      setalertMessage(data?.response?.data?.error);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.overlay}>
          <Header />

          <Container>
            <div className={classes.formCont}>
              <div className={classes.formBg}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  Book a Free Demo Class Today
                </p>
                <div style={{ marginRight: 20, marginLeft: 20 }}>
                  <AntForm
                    getSubjects={getSubjects}
                    timeZones={timeZones}
                    getbackData={getbackData}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <SnackbarAlert
        setOpen={setAlertOpen}
        open={alertOpen}
        message={alertMessage}
        color={alertColor}
      />
      <Footer />
    </>
  );
};

export default Booking;
