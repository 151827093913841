import { Container } from "@material-ui/core";
import React, { useEffect } from "react";

import styles from "../styles.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Header";
import Footer from "../Footer";
import Headernew from "../../Components/Headernew";

const useStyles = makeStyles((theme) => ({
  formCont: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  formBg: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ecf0f1",
    borderRadius: 10,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
const Tos = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <div className={styles.container}>
        <div className={styles.overlay}> */}
      <Headernew />
      <Container>
        <div className={classes.formCont}>
          <div className={classes.formBg}>
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 40,
                  fontWeight: "bold",
                  color: "#273c75",
                  paddingTop: 20,
                  paddigBottom: 10,
                }}
              >
                TERMS OF USE
              </p>

              <Container style={{ paddingTop: 10, width: "90%" }}>
                <div data-custom-class="body">
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: 1 }}
                  >
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "center", lineHeight: "150%" }}
                      >
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ lineHeight: "22.5px" }}
                        >
                          <div
                            align="center"
                            className="MsoNormal"
                            style={{ lineHeight: "150%" }}
                          >
                            <div
                              align="center"
                              className="MsoNormal"
                              data-custom-class="title"
                              style={{
                                textAlign: "left",
                                lineHeight: "1.5",
                              }}
                            >
                              <strong>
                                <span
                                  style={{
                                    lineHeight: "22.5px",
                                    fontSize: "26px",
                                  }}
                                >
                                  TERMS OF USE
                                </span>
                              </strong>
                            </div>
                            <div
                              align="center"
                              className="MsoNormal"
                              style={{
                                lineHeight: "22.5px",
                                textAlign: "left",
                              }}
                            ></div>
                            <div
                              align="center"
                              className="MsoNormal"
                              data-custom-class="subtitle"
                              style={{
                                textAlign: "left",
                                lineHeight: "22.5px",
                              }}
                            >
                              <br />
                            </div>
                            <div
                              align="center"
                              className="MsoNormal"
                              data-custom-class="subtitle"
                              style={{
                                textAlign: "left",
                                lineHeight: "1.5",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "14.6667px",
                                  textAlign: "justify",
                                }}
                              >
                                <strong>
                                  Last updated{" "}
                                  <bdt
                                    className="block-container question question-in-editor"
                                    data-id="e2088df5-25ea-aec9-83d4-6284f5a7e043"
                                    data-type="question"
                                  >
                                    June 01, 2020
                                  </bdt>
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{
                            lineHeight: "17.25px",
                            textAlign: "left",
                          }}
                        >
                          <br />
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{
                            lineHeight: "17.25px",
                            textAlign: "left",
                          }}
                        >
                          <br />
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{
                            lineHeight: "17.25px",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                            }}
                          >
                            <br />
                          </span>
                        </div>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            AGREEMENT TO TERMS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          These Terms of Use constitute a legally binding
                          agreement made between you, whether personally or on
                          behalf of an entity (“you”) and{" "}
                          <bdt
                            className="block-container question question-in-editor"
                            data-id="4ab94aa9-19d1-61e0-711e-42c7d186232b"
                            data-type="question"
                          >
                            Live Sloka
                          </bdt>
                          <bdt className="block-component" /> ("
                          <bdt className="block-component" />
                          <strong>Company</strong>
                          <bdt className="statement-end-if-in-editor" />
                          ", “<strong>we</strong>”, “<strong>us</strong>”, or “
                          <strong>our</strong>”), concerning your access to and
                          use of the{" "}
                          <bdt
                            className="block-container question question-in-editor"
                            data-id="92c3b320-1d8b-c74c-db68-d12810736807"
                            data-type="question"
                          >
                            <a
                              href="https://mylivesloka.com"
                              target="_blank"
                              rel="noreferrer"
                              data-custom-class="link"
                            >
                              https://mylivesloka.com
                            </a>
                          </bdt>{" "}
                          website as well as any other media form, media
                          channel, mobile website or mobile application related,
                          linked, or otherwise connected thereto (collectively,
                          the “Site”). You agree that by accessing the Site, you
                          have read, understood, and agreed to be bound by all
                          of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
                          THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
                          FROM USING THE SITE AND YOU MUST DISCONTINUE USE
                          IMMEDIATELY.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          Supplemental terms and conditions or documents that
                          may be posted on the Site from time to time are hereby
                          expressly incorporated herein by reference. We reserve
                          the right, in our sole discretion, to make changes or
                          modifications to these Terms of Use at any time and
                          for any reason. We will alert you about any changes by
                          updating the “Last updated” date of these Terms of
                          Use, and you waive any right to receive specific
                          notice of each such change. It is your responsibility
                          to periodically review these Terms of Use to stay
                          informed of updates. You will be subject to, and will
                          be deemed to have been made aware of and to have
                          accepted, the changes in any revised Terms of Use by
                          your continued use of the Site after the date such
                          revised Terms of Use are posted.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          The information provided on the Site is not intended
                          for distribution to or use by any person or entity in
                          any jurisdiction or country where such distribution or
                          use would be contrary to law or regulation or which
                          would subject us to any registration requirement
                          within such jurisdiction or country. Accordingly,
                          those persons who choose to access the Site from other
                          locations do so on their own initiative and are solely
                          responsible for compliance with local laws, if and to
                          the extent local laws are applicable.
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              msoFareastFontFamily: "Calibri",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          The Site is not tailored to comply with
                          industry-specific regulations (Health Insurance
                          Portability and Accountability Act (HIPAA), Federal
                          Information Security Management Act (FISMA), etc.), so
                          if your interactions would be subjected to such laws,
                          you may not use this Site. You may not use the Site in
                          a way that would violate the Gramm-Leach-Bliley Act
                          (GLBA).
                          <bdt className="block-component">
                            <span
                              style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                msoFareastFontFamily: "Calibri",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166,
                              }}
                            >
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </bdt>
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="user_o18_option"
                            data-type="statement"
                          >
                            <span style={{ fontSize: "15px" }} />
                          </bdt>
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                          >
                            <bdt data-type="conditional-block">
                              <bdt data-type="body">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "14.6667px",
                                  }}
                                >
                                  The Site is intended for users who are at
                                  least 13 years of age. All users who are
                                  minors in the jurisdiction in which they
                                  reside (generally under the age of 18) must
                                  have the permission of, and be directly
                                  supervised by, their parent or guardian to use
                                  the Site. If you are a minor, you must have
                                  your parent or guardian read and agree to
                                  these Terms of Use prior to you using the
                                  Site.
                                </span>
                              </bdt>
                            </bdt>
                          </bdt>
                          <bdt
                            className="block-component"
                            data-record-question-key="user_o18_option"
                            data-type="statement"
                          />
                        </bdt>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            INTELLECTUAL PROPERTY RIGHTS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          Unless otherwise indicated, the Site is our
                          proprietary property and all source code, databases,
                          functionality, software, website designs, audio,
                          video, text, photographs, and graphics on the Site
                          (collectively, the “Content”) and the trademarks,
                          service marks, and logos contained therein (the
                          “Marks”) are owned or controlled by us or licensed to
                          us, and are protected by copyright and trademark laws
                          and various other intellectual property rights and
                          unfair competition laws of the United States,
                          international copyright laws, and international
                          conventions. The Content and the Marks are provided on
                          the Site “AS IS” for your information and personal use
                          only. Except as expressly provided in these Terms of
                          Use, no part of the Site and no Content or Marks may
                          be copied, reproduced, aggregated, republished,
                          uploaded, posted, publicly displayed, encoded,
                          translated, transmitted, distributed, sold, licensed,
                          or otherwise exploited for any commercial purpose
                          whatsoever, without our express prior written
                          permission.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          Provided that you are eligible to use the Site, you
                          are granted a limited license to access and use the
                          Site and to download or print a copy of any portion of
                          the Content to which you have properly gained access
                          solely for your personal, non-commercial use. We
                          reserve all rights not expressly granted to you in and
                          to the Site, the Content and the Marks.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            USER REPRESENTATIONS
                          </span>
                        </strong>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div
                        className="MsoNormal"
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-ideograph",
                          lineHeight: "115%",
                        }}
                      >
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            By using the Site, you represent and warrant that:{" "}
                          </span>
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="user_account_option"
                                data-type="statement"
                              />
                              &nbsp;
                            </bdt>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              (
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "14.6667px",
                              }}
                            >
                              1
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              ) you have the legal capacity and you agree to
                              comply with these Terms of Use;
                            </span>
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_u13_option"
                                  data-type="statement"
                                />{" "}
                                <bdt data-type="body">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "11pt",
                                    }}
                                  >
                                    (
                                  </span>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "14.6667px",
                                    }}
                                  >
                                    2
                                  </span>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "11pt",
                                    }}
                                  >
                                    ) you are not under the age of 13;
                                  </span>
                                </bdt>
                              </bdt>
                              <bdt
                                className="statement-end-if-in-editor"
                                data-type="close"
                              />
                            </bdt>
                          </bdt>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt",
                            }}
                          >
                            &nbsp;(
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          >
                            3
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt",
                            }}
                          >
                            ) you are not a minor in the jurisdiction in which
                            you reside
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="76948fab-ec9e-266a-bb91-948929c050c9"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_o18_option"
                                  data-type="statement"
                                />
                                <bdt data-type="body">
                                  , or if a minor, you have received parental
                                  permission to use the Site
                                </bdt>
                              </bdt>
                              <bdt
                                className="statement-end-if-in-editor"
                                data-type="close"
                              />
                            </bdt>
                            ; (
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          >
                            4
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt",
                            }}
                          >
                            ) you will not access the Site through automated or
                            non-human means, whether through a bot, script, or
                            otherwise; (
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          >
                            5
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt",
                            }}
                          >
                            ) you will not use the Site for any illegal or
                            unauthorized purpose; and (
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          >
                            6
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt",
                            }}
                          >
                            ) your use of the Site will not violate any
                            applicable law or regulation.
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-ideograph",
                          lineHeight: "115%",
                        }}
                      >
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            If you provide any information that is untrue,
                            inaccurate, not current, or incomplete, we have the
                            right to suspend or terminate your account and
                            refuse any and all current or future use of the Site
                            (or any portion thereof).
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                      <br />
                    </div>
                    <div
                      align="center"
                      className="MsoNormal"
                      style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="user_account_option"
                            data-type="statement"
                          >
                            <span style={{ fontSize: "15px" }} />
                          </bdt>
                        </bdt>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "17.25px" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "24.5333px",
                                fontSize: "19px",
                              }}
                            >
                              PROHIBITED ACTIVITIES
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            You may not access or use the Site for any purpose
                            other than that for which we make the Site
                            available. The Site may not be used in connection
                            with any commercial endeavors except those that are
                            specifically endorsed or approved by us.
                          </span>
                        </div>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <div
                          className="MsoNormal"
                          style={{
                            textAlign: "justify",
                            lineHeight: "17.25px",
                          }}
                        >
                          <div
                            className="MsoNormal"
                            style={{ lineHeight: "17.25px" }}
                          >
                            <div
                              className="MsoNormal"
                              data-custom-class="body_text"
                              style={{
                                lineHeight: "1.5",
                                textAlign: "left",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                As a user of the Site, you agree not to:
                              </span>
                            </div>
                            <div
                              className="MsoNormal"
                              style={{ lineHeight: "17.25px" }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <div
                                  className="MsoNormal"
                                  style={{
                                    color: "rgb(10, 54, 90)",
                                    fontSize: "15px",
                                    lineHeight: 1,
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </div>
                              </span>
                            </div>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: "15px",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "justify",
                                  textIndent: "-29.4px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  backgroundColor: "rgb(255, 255, 255)",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="block-component" />
                                <span
                                  style={{
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontStyle: "normal",
                                      fontVariantLigatures: "normal",
                                      fontVariantCaps: "normal",
                                      fontWeight: 400,
                                      letterSpacing: "normal",
                                      orphans: 2,
                                      textAlign: "justify",
                                      textIndent: "-22.05pt",
                                      textTransform: "none",
                                      whiteSpace: "normal",
                                      widows: 2,
                                      wordSpacing: "0px",
                                      WebkitTextStrokeWidth: "0px",
                                      backgroundColor: "rgb(255, 255, 255)",
                                      textDecorationStyle: "initial",
                                      textDecorationColor: "initial",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt
                                      className="block-container forloop"
                                      data-type="forloop"
                                      id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                      style={{ display: "inline" }}
                                    >
                                      <bdt
                                        data-type="conditional-block"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="body"
                                          style={{ display: "inline" }}
                                        >
                                          1
                                        </bdt>
                                      </bdt>
                                    </bdt>
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontStyle: "normal",
                                      fontVariantLigatures: "normal",
                                      fontVariantCaps: "normal",
                                      fontWeight: 400,
                                      letterSpacing: "normal",
                                      orphans: 2,
                                      textAlign: "justify",
                                      textIndent: "-29.4px",
                                      textTransform: "none",
                                      whiteSpace: "normal",
                                      widows: 2,
                                      wordSpacing: "0px",
                                      WebkitTextStrokeWidth: "0px",
                                      backgroundColor: "rgb(255, 255, 255)",
                                      textDecorationStyle: "initial",
                                      textDecorationColor: "initial",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    .&nbsp;
                                  </span>
                                </span>
                                Systematically retrieve data or other content
                                from the Site to create or compile, directly or
                                indirectly, a collection, compilation, database,
                                or directory without written permission from us.
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            2
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Trick, defraud, or mislead us and other
                                      users, especially in any attempt to learn
                                      sensitive account information such as user
                                      passwords.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            3
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Circumvent, disable, or otherwise
                                      interfere with security-related features
                                      of the Site, including features that
                                      prevent or restrict the use or copying of
                                      any Content or enforce limitations on the
                                      use of the Site and/or the Content
                                      contained therein.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            4
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Disparage, tarnish, or otherwise harm,
                                      in our opinion, us and/or the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            5
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Use any information obtained from the
                                      Site in order to harass, abuse, or harm
                                      another person.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            6
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Make improper use of our support
                                      services or submit false reports of abuse
                                      or misconduct.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            7
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Use the Site in a manner inconsistent
                                      with any applicable laws or regulations.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            8
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Use the Site to advertise or offer to
                                      sell goods and services.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            9
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Engage in unauthorized framing of or
                                      linking to the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            10
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Upload or transmit (or attempt to upload
                                      or to transmit) viruses, Trojan horses, or
                                      other material, including excessive use of
                                      capital letters and spamming (continuous
                                      posting of repetitive text), that
                                      interferes with any party’s uninterrupted
                                      use and enjoyment of the Site or modifies,
                                      impairs, disrupts, alters, or interferes
                                      with the use, features, functions,
                                      operation, or maintenance of the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            11
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Engage in any automated use of the
                                      system, such as using scripts to send
                                      comments or messages, or using any data
                                      mining, robots, or similar data gathering
                                      and extraction tools.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            12
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Delete the copyright or other
                                      proprietary rights notice from any
                                      Content.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            13
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Attempt to impersonate another user or
                                      person or use the username of another
                                      user.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            14
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Sell or otherwise transfer your profile.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            15
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Upload or transmit (or attempt to upload
                                      or to transmit) any material that acts as
                                      a passive or active information collection
                                      or transmission mechanism, including
                                      without limitation, clear graphics
                                      interchange formats (“gifs”), 1×1 pixels,
                                      web bugs, cookies, or other similar
                                      devices (sometimes referred to as
                                      “spyware” or “passive collection
                                      mechanisms” or “pcms”).
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            16
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Interfere with, disrupt, or create an
                                      undue burden on the Site or the networks
                                      or services connected to the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            17
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Harass, annoy, intimidate, or threaten
                                      any of our employees or agents engaged in
                                      providing any portion of the Site to you.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            18
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Attempt to bypass any measures of the
                                      Site designed to prevent or restrict
                                      access to the Site, or any portion of the
                                      Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            19
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Copy or adapt the Site’s software,
                                      including but not limited to Flash, PHP,
                                      HTML, JavaScript, or other code.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            20
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Decipher, decompile, disassemble, or
                                      reverse engineer any of the software
                                      comprising or in any way making up a part
                                      of the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            21
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Except as may be&nbsp;the result of
                                      standard search engine or Internet browser
                                      usage, use, launch, develop, or distribute
                                      any automated system, including without
                                      limitation, any spider, robot, cheat
                                      utility, scraper, or offline reader that
                                      accesses the Site, or using or launching
                                      any unauthorized script or other software.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            22
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Use a buying agent or purchasing agent
                                      to make purchases on the Site.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            23
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Make any unauthorized use of the Site,
                                      including collecting usernames and/or
                                      email addresses of users by electronic or
                                      other means for the purpose of sending
                                      unsolicited email, or creating user
                                      accounts by automated means or under false
                                      pretenses.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="block-component" />
                              <span
                                style={{
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontStyle: "normal",
                                    fontVariantLigatures: "normal",
                                    fontVariantCaps: "normal",
                                    fontWeight: 400,
                                    letterSpacing: "normal",
                                    orphans: 2,
                                    textAlign: "justify",
                                    textIndent: "-29.4px",
                                    textTransform: "none",
                                    whiteSpace: "normal",
                                    widows: 2,
                                    wordSpacing: "0px",
                                    WebkitTextStrokeWidth: "0px",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    textDecorationStyle: "initial",
                                    textDecorationColor: "initial",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-22.05pt",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-container forloop"
                                        data-type="forloop"
                                        id="19beb913-5a5e-2b51-51f9-8600a8eb26c3"
                                        style={{ display: "inline" }}
                                      >
                                        <bdt
                                          data-type="conditional-block"
                                          style={{ display: "inline" }}
                                        >
                                          <bdt
                                            data-type="body"
                                            style={{ display: "inline" }}
                                          >
                                            24
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontStyle: "normal",
                                        fontVariantLigatures: "normal",
                                        fontVariantCaps: "normal",
                                        fontWeight: 400,
                                        letterSpacing: "normal",
                                        orphans: 2,
                                        textAlign: "justify",
                                        textIndent: "-29.4px",
                                        textTransform: "none",
                                        whiteSpace: "normal",
                                        widows: 2,
                                        wordSpacing: "0px",
                                        WebkitTextStrokeWidth: "0px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        textDecorationStyle: "initial",
                                        textDecorationColor: "initial",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      . Use the Site as part of any effort to
                                      compete with us or otherwise use the Site
                                      and/or the Content for any
                                      revenue-generating endeavor or commercial
                                      enterprise.
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                              marginLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="forloop-component" />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            style={{ textAlign: "left", lineHeight: "1.5" }}
                          ></div>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{
                            textAlign: "justify",
                            lineHeight: "1.5",
                          }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{
                            textAlign: "justify",
                            lineHeight: "1.5",
                          }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="heading_1"
                                style={{
                                  lineHeight: "17.25px",
                                  textAlign: "left",
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "24.5333px",
                                      fontSize: "19px",
                                    }}
                                  >
                                    USER GENERATED CONTRIBUTIONS
                                  </span>
                                </strong>
                                &nbsp;
                              </div>
                            </bdt>
                          </bdt>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{
                                  lineHeight: "1.5",
                                  textAlign: "left",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="block-component" />
                                  The Site does not offer users to submit or
                                  post content. We may provide you with the
                                  opportunity to create, submit, post, display,
                                  transmit, perform, publish, distribute, or
                                  broadcast content and materials to us or on
                                  the Site, including but not limited to text,
                                  writings, video, audio, photographs, graphics,
                                  comments, suggestions, or personal information
                                  or other material (collectively,
                                  "Contributions"). Contributions may be
                                  viewable by other users of the Site and
                                  through third-party websites. As such, any
                                  Contributions you transmit may be treated in
                                  accordance with the Site Privacy Policy. When
                                  you create or make available any
                                  Contributions, you thereby represent and
                                  warrant that:
                                  <span
                                    style={{
                                      fontSize: "11pt",
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </div>
                            </bdt>
                          </bdt>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                style={{ lineHeight: 1, textAlign: "left" }}
                              >
                                <br />
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: "17.25px",
                                }}
                              >
                                <div
                                  className="MsoNormal"
                                  data-custom-class="body_text"
                                  style={{
                                    lineHeight: "1.5",
                                    marginLeft: "20px",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      1. &nbsp;The creation, distribution,
                                      transmission, public display, or
                                      performance, and the accessing,
                                      downloading, or copying of your
                                      Contributions do not and will not infringe
                                      the proprietary rights, including but not
                                      limited to the copyright, patent,
                                      trademark, trade secret, or moral rights
                                      of any third party.
                                      <br />
                                      2. &nbsp;You are the creator and owner of
                                      or have the necessary licenses, rights,
                                      consents, releases, and permissions to use
                                      and to authorize us, the Site, and other
                                      users of the Site to use your
                                      Contributions in any manner contemplated
                                      by the Site and these Terms of Use.
                                      <br />
                                      3. &nbsp;You have the written consent,
                                      release, and/or permission of each and
                                      every identifiable individual person in
                                      your Contributions to use the name or
                                      likeness of each and every such
                                      identifiable individual person to enable
                                      inclusion and use of your Contributions in
                                      any manner contemplated by the Site and
                                      these Terms of Use.
                                      <br />
                                      4. &nbsp;Your Contributions are not false,
                                      inaccurate, or misleading.
                                      <br />
                                      5. &nbsp;Your Contributions are not
                                      unsolicited or unauthorized advertising,
                                      promotional materials, pyramid schemes,
                                      chain letters, spam, mass mailings, or
                                      other forms of solicitation.
                                      <br />
                                      6. &nbsp;Your Contributions are not
                                      obscene, lewd, lascivious, filthy,
                                      violent, harassing, libelous, slanderous,
                                      or otherwise objectionable (as determined
                                      by us).
                                      <br />
                                      7. &nbsp;Your Contributions do not
                                      ridicule, mock, disparage, intimidate, or
                                      abuse anyone.
                                      <br />
                                      8. &nbsp;Your Contributions are not used
                                      to harass or threaten (in the legal sense
                                      of those terms) any other person and to
                                      promote violence against a specific person
                                      or class of people.
                                      <br />
                                      9. &nbsp;Your Contributions do not violate
                                      any applicable law, regulation, or rule.
                                      <br />
                                      10. &nbsp;Your Contributions do not
                                      violate the privacy or publicity rights of
                                      any third party.
                                      <br />
                                      11. &nbsp;Your Contributions do not
                                      contain any material that solicits
                                      personal information from anyone under the
                                      age of 18 or exploits people under the age
                                      of 18 in a sexual or violent manner.
                                      <br />
                                      12. &nbsp;Your Contributions do not
                                      violate any applicable law concerning
                                      child pornography, or otherwise intended
                                      to protect the health or well-being of
                                      minors.
                                      <br />
                                      13. &nbsp;Your Contributions do not
                                      include any offensive comments that are
                                      connected to race, national origin,
                                      gender, sexual preference, or physical
                                      handicap.
                                      <br />
                                      14. &nbsp;Your Contributions do not
                                      otherwise violate, or link to material
                                      that violates, any provision of these
                                      Terms of Use, or any applicable law or
                                      regulation.
                                    </span>
                                  </span>
                                </div>
                                <div
                                  className="MsoNormal"
                                  style={{
                                    lineHeight: 1,
                                    marginLeft: "20px",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </div>
                              </div>
                              <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{
                                  lineHeight: "1.5",
                                  textAlign: "left",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  Any use of the Site in violation of the
                                  foregoing violates these Terms of Use and may
                                  result in, among other things, termination or
                                  suspension of your rights to use the Site.
                                </span>
                              </div>
                            </bdt>
                          </bdt>
                        </div>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="heading_1"
                                style={{
                                  lineHeight: "1.5",
                                  textAlign: "left",
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "24.5333px",
                                      fontSize: "19px",
                                    }}
                                  >
                                    CONTRIBUTION LICENSE
                                  </span>
                                </strong>
                              </div>
                            </bdt>
                          </bdt>
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                style={{
                                  lineHeight: "17.25px",
                                  textAlign: "left",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="block-component" />
                                </span>
                              </div>
                            </bdt>
                          </bdt>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "justify", lineHeight: 1 }}
                        >
                          <br />
                        </div>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          You and the Site agree that we may access, store,
                          process, and use any information and personal data
                          that you provide following the terms of the Privacy
                          Policy and your choices (including settings).
                          <br />
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          By submitting suggestions or other feedback regarding
                          the Site, you agree that we can use and share such
                          feedback for any purpose without compensation to
                          you.&nbsp;
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          We do not assert any ownership over your
                          Contributions. You retain full ownership of all of
                          your Contributions and any intellectual property
                          rights or other proprietary rights associated with
                          your Contributions. We are not liable for any
                          statements or representations in your Contributions
                          provided by you in any area on the Site. You are
                          solely responsible for your Contributions to the Site
                          and you expressly agree to exonerate us from any and
                          all responsibility and to refrain from any legal
                          action against us regarding your Contributions.
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                        >
                          <bdt data-type="conditional-block">
                            <bdt
                              className="block-component"
                              data-record-question-key="review_option"
                              data-type="statement"
                            >
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </bdt>
                        </bdt>
                      </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                      <span style={{ fontSize: "15px" }}></span>
                    </div>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="mobile_app_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                      </bdt>
                    </bdt>
                  </div>
                  <div align="center">
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                    </span>
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    style={{ lineHeight: "115%" }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        SUBMISSIONS
                      </span>
                    </strong>
                  </div>
                  <div style={{ lineHeight: 1 }}>
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          color: "#595959",
                          msoThemecolor: "text1",
                          msoThemetint: 166,
                        }}
                      >
                        You acknowledge and agree that any questions, comments,
                        suggestions, ideas, feedback, or other information
                        regarding the Site ("Submissions") provided by you to us
                        are non-confidential and shall become our sole property.
                        We shall own exclusive rights, including all
                        intellectual property rights, and shall be entitled to
                        the unrestricted use and dissemination of these
                        Submissions for any lawful purpose, commercial or
                        otherwise, without acknowledgment or compensation to
                        you. You hereby waive all moral rights to any such
                        Submissions, and you hereby warrant that any such
                        Submissions are original with you or that you have the
                        right to submit such Submissions. You agree there shall
                        be no recourse against us for any alleged or actual
                        infringement or misappropriation of any proprietary
                        right in your Submissions.
                      </span>
                    </span>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div align="center">
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                    </span>
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: 1 }}
                  >
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                      <span style={{ fontSize: "15px" }}></span>
                    </div>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="14038561-dad7-be9d-370f-f8aa487b2570"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="advertiser_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                      </bdt>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            SITE MANAGEMENT
                          </span>
                        </strong>
                      </div>
                    </bdt>
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: 1 }}
                  >
                    <br />
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <div
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5" }}
                    >
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          color: "#595959",
                          msoThemecolor: "text1",
                          msoThemetint: 166,
                        }}
                      >
                        We reserve the right, but not the obligation, to: (1)
                        monitor the Site for violations of these Terms of Use;
                        (2) take appropriate legal action against anyone who, in
                        our sole discretion, violates the law or these Terms of
                        Use, including without limitation, reporting such user
                        to law enforcement authorities; (3) in our sole
                        discretion and without limitation, refuse, restrict
                        access to, limit the availability of, or disable (to the
                        extent technologically feasible) any of your
                        Contributions or any portion thereof; (4) in our sole
                        discretion and without limitation, notice, or liability,
                        to remove from the Site or otherwise disable all files
                        and content that are excessive in size or are in any way
                        burdensome to our systems; and (5) otherwise manage the
                        Site in a manner designed to protect our rights and
                        property and to facilitate the proper functioning of the
                        Site.
                      </span>
                    </div>
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <br />
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                      <br />
                    </div>
                    <div
                      className="MsoNormal"
                      style={{ lineHeight: "1.5" }}
                    ></div>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="privacy_policy_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>{" "}
                        <bdt data-type="body">
                          <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            style={{ lineHeight: "1.5" }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: "19px",
                                }}
                              >
                                PRIVACY POLICY
                              </span>
                            </strong>
                          </div>
                          <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <span
                              style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166,
                              }}
                            >
                              <br />
                            </span>
                          </div>
                          <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                          >
                            <span
                              style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166,
                              }}
                            >
                              We care about data privacy and security.&nbsp;
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "14.6667px",
                              }}
                            >
                              Please review our Privacy Policy:
                            </span>
                            <b
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "10px",
                              }}
                            >
                              &nbsp;
                              <bdt
                                className="block-container question question-in-editor"
                                data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                                data-type="question"
                              >
                                <a
                                  href="https://app.termly.io/document/privacy-policy/39646b46-a86f-4727-b986-70d492ce6c1b"
                                  target="_blank"
                                  rel="noreferrer"
                                  data-custom-class="link"
                                >
                                  https://app.termly.io/document/privacy-policy/39646b46-a86f-4727-b986-70d492ce6c1b
                                </a>
                              </bdt>
                            </b>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "14.6667px",
                              }}
                            >
                              .&nbsp;
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              By using the Site, you agree to be bound by our
                              Privacy Policy, which is incorporated into these
                              Terms of Use. Please be advised the Site is hosted
                              in{" "}
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="block-component" />
                                the{" "}
                                <bdt className="question">United States</bdt>
                                <bdt className="block-component" />
                              </span>
                              . If you access the Site from any other region of
                              the world with laws or other requirements
                              governing personal data collection, use, or
                              disclosure that differ from applicable laws in{" "}
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="block-component" />
                                the{" "}
                                <bdt className="question">United States</bdt>
                                <bdt className="block-component" />
                              </span>
                              , then through your continued use of the Site, you
                              are transferring your data to{" "}
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="block-component" />
                                the{" "}
                                <bdt className="question">United States</bdt>
                                <bdt className="block-component" />
                              </span>
                              , and you agree to have your data transferred to
                              and processed in{" "}
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="block-component" />
                                the{" "}
                                <bdt className="question">United States</bdt>
                                <bdt className="block-component" />
                              </span>
                              . <bdt className="block-component" />
                            </span>
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_u13_option"
                                  data-type="statement"
                                />
                                <bdt data-type="body">
                                  Further, we do not knowingly accept, request,
                                  or solicit information from children or
                                  knowingly market to children. Therefore, in
                                  accordance with the U.S. Children’s Online
                                  Privacy Protection Act, if we receive actual
                                  knowledge that anyone under the age of 13 has
                                  provided personal information to us without
                                  the requisite and verifiable parental consent,
                                  we will delete that information from the Site
                                  as quickly as is reasonably practical.
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "11pt",
                                    }}
                                  >
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </bdt>
                              </bdt>
                            </bdt>
                          </div>
                        </bdt>
                      </bdt>
                    </bdt>
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <br />
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <br />
                    </span>
                  </div>
                  <div
                    align="center"
                    style={{ textAlign: "left", lineHeight: 1 }}
                  >
                    <bdt className="block-container if" data-type="if">
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      >
                        <span style={{ fontSize: "15px" }} />
                      </bdt>
                    </bdt>
                    <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="privacy_policy_followup"
                            data-type="statement"
                            style={{ fontSize: "14.6667px" }}
                          >
                            <span style={{ fontSize: "15px" }} />
                          </bdt>
                        </bdt>
                      </bdt>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                      <span style={{ fontSize: "15px" }}></span>
                    </div>
                    <bdt className="block-component">
                      <bdt className="block-component" />
                      <bdt className="block-container if" data-type="if">
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                      </bdt>
                    </bdt>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                      <span style={{ fontSize: "15px" }}></span>
                      <bdt className="block-component">
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </bdt>
                      <bdt className="block-component" />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            TERM AND TERMINATION
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          These Terms of Use shall remain in full force and
                          effect while you use the Site. WITHOUT LIMITING ANY
                          OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE
                          RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                          LIABILITY, DENY ACCESS TO AND USE OF THE SITE
                          (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
                          PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
                          WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE
                          OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                          TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
                          DELETE{" "}
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="user_account_option"
                                data-type="statement"
                              />
                            </bdt>
                            ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                            TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                          </bdt>
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          If we terminate or suspend your account for any
                          reason, you are prohibited from registering and
                          creating a new account under your name, a fake or
                          borrowed name, or the name of any third party, even if
                          you may be acting on behalf of the third party. In
                          addition to terminating or suspending your account, we
                          reserve the right to take appropriate legal action,
                          including without limitation pursuing civil, criminal,
                          and injunctive redress.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                            }}
                          >
                            <span style={{ fontSize: "19px" }}>
                              MODIFICATIONS AND INTERRUPTIONS
                            </span>
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          We reserve the right to change, modify, or remove the
                          contents of the Site at any time or for any reason at
                          our sole discretion without notice. However, we have
                          no obligation to update any information on our Site.
                          We also reserve the right to modify or discontinue all
                          or part of the Site without notice at any time. We
                          will not be liable to you or any third party for any
                          modification, price change, suspension, or
                          discontinuance of the Site. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          We cannot guarantee the Site will be available at all
                          times. We may experience hardware, software, or other
                          problems or need to perform maintenance related to the
                          Site, resulting in interruptions, delays, or errors.
                          We reserve the right to change, revise, update,
                          suspend, discontinue, or otherwise modify the Site at
                          any time or for any reason without notice to you. You
                          agree that we have no liability whatsoever for any
                          loss, damage, or inconvenience caused by your
                          inability to access or use the Site during any
                          downtime or discontinuance of the Site. Nothing in
                          these Terms of Use will be construed to obligate us to
                          maintain and support the Site or to supply any
                          corrections, updates, or releases in connection
                          therewith.
                        </span>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                            }}
                          >
                            <span style={{ fontSize: "19px" }}>
                              GOVERNING LAW
                            </span>
                          </span>
                        </strong>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            These Terms shall be governed by and defined
                            following the laws of{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "11pt",
                                      lineHeight: "16.8667px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt className="block-component" />
                                    <span
                                      style={{
                                        fontSize: "11pt",
                                        lineHeight: "16.8667px",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "11pt",
                                          lineHeight: "16.8667px",
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <bdt className="question">India</bdt>
                                      </span>
                                    </span>
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        . <bdt className="question">Live Sloka</bdt> and
                        yourself irrevocably consent that the courts of{" "}
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt className="block-component" />
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="question">India</bdt>
                              </span>
                            </span>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                          &nbsp;
                        </span>
                        shall have exclusive jurisdiction to resolve any dispute
                        which may arise in connection with these terms.
                        <span style={{ fontSize: "15px" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            DISPUTE RESOLUTION
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      align="center"
                      style={{ textAlign: "left", lineHeight: 1 }}
                    >
                      <br />
                    </div>
                    <div align="center" style={{ textAlign: "left" }}>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                        >
                          <bdt data-type="conditional-block">
                            <bdt
                              className="block-component"
                              data-record-question-key="dispute_option"
                              data-type="statement"
                            />{" "}
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="heading_2"
                                style={{
                                  lineHeight: "17.25px",
                                  textAlign: "left",
                                }}
                              >
                                <strong>Informal Negotiations</strong>
                              </div>
                            </bdt>
                          </bdt>
                        </bdt>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt className="block-container if" data-type="if">
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{
                                  textAlign: "left",
                                  lineHeight: "1.5",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  To expedite resolution and control the cost of
                                  any dispute, controversy, or claim related to
                                  these Terms of Use (each a "Dispute" and
                                  collectively, the “Disputes”) brought by
                                  either you or us (individually, a “Party” and
                                  collectively, the “Parties”), the Parties
                                  agree to first attempt to negotiate any
                                  Dispute (except those Disputes expressly
                                  provided below) informally for at least{" "}
                                  <bdt
                                    className="block-container question question-in-editor"
                                    data-id="33108d82-92fb-fbe9-6ef5-01cac7097351"
                                    data-type="question"
                                  >
                                    ninety (90)
                                  </bdt>{" "}
                                  days before initiating arbitration. Such
                                  informal negotiations commence upon written
                                  notice from one Party to the other Party.
                                </span>
                              </div>
                            </bdt>
                          </bdt>
                        </bdt>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt className="block-container if" data-type="if">
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="heading_2"
                                style={{
                                  lineHeight: "17.25px",
                                  textAlign: "left",
                                }}
                              >
                                <strong>Binding Arbitration</strong>
                              </div>
                            </bdt>
                          </bdt>
                        </bdt>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt className="block-container if" data-type="if">
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{
                                  textAlign: "left",
                                  lineHeight: "1.5",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="block-component" />
                                </span>
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                      <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{
                                          textAlign: "left",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          Any dispute arising out of or in
                                          connection with this contract,
                                          including any question regarding its
                                          existence, validity, or termination,
                                          shall be referred to and finally
                                          resolved by the International
                                          Commercial Arbitration Court under the
                                          European Arbitration Chamber (Belgium,
                                          Brussels, Avenue Louise, 146)
                                          according to the Rules of this ICAC,
                                          which, as a result of referring to it,
                                          is considered as the part of this
                                          clause. The number of arbitrators
                                          shall be{" "}
                                          <bdt className="question">
                                            three (3)
                                          </bdt>
                                          . The seat, or legal place, of
                                          arbitration shall be{" "}
                                          <span
                                            style={{
                                              fontSize: "11pt",
                                              lineHeight: "16.8667px",
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <bdt className="block-component" />
                                            <bdt className="question">
                                              hyderabad
                                            </bdt>
                                            ,{" "}
                                            <bdt className="block-component" />
                                            <bdt className="question">
                                              India
                                            </bdt>
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                        <bdt className="else-block" />.
                                        <span
                                          style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "11pt",
                                              lineHeight: "16.8667px",
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            &nbsp;The language of the
                                            proceedings shall be{" "}
                                            <bdt className="question">
                                              english
                                            </bdt>
                                            .
                                          </span>
                                        </span>{" "}
                                        The governing law of the contract shall
                                        be the substantive law of{" "}
                                        <span
                                          style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "11pt",
                                              lineHeight: "16.8667px",
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <bdt className="block-component" />
                                            <span
                                              style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "11pt",
                                                  lineHeight: "16.8667px",
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <bdt className="block-component" />
                                                <bdt className="question">
                                                  India
                                                </bdt>
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor" />
                                        .
                                        <bdt className="statement-end-if-in-editor" />
                                      </div>
                                      <div
                                        className="MsoNormal"
                                        style={{
                                          lineHeight: "17.25px",
                                          textAlign: "left",
                                        }}
                                      ></div>
                                    </bdt>
                                  </bdt>
                                </bdt>
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <br />
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                      <div
                                        className="MsoNormal"
                                        data-custom-class="heading_2"
                                        style={{
                                          lineHeight: "17.25px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <strong>Restrictions</strong>
                                      </div>
                                    </bdt>
                                  </bdt>
                                </bdt>
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <br />
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                      <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{
                                          textAlign: "left",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          The Parties agree that any arbitration
                                          shall be limited to the Dispute
                                          between the Parties individually. To
                                          the full extent permitted by law, (a)
                                          no arbitration shall be joined with
                                          any other proceeding; (b) there is no
                                          right or authority for any Dispute to
                                          be arbitrated on a class-action basis
                                          or to utilize class action procedures;
                                          and (c) there is no right or authority
                                          for any Dispute to be brought in a
                                          purported representative capacity on
                                          behalf of the general public or any
                                          other persons.
                                        </span>
                                      </div>
                                    </bdt>
                                  </bdt>
                                </bdt>
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <br />
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                      <div
                                        className="MsoNormal"
                                        data-custom-class="heading_2"
                                        style={{
                                          lineHeight: "17.25px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <strong>
                                          Exceptions to Informal Negotiations
                                          and Arbitration
                                        </strong>
                                      </div>
                                    </bdt>
                                  </bdt>
                                </bdt>
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <br />
                              </div>
                              <div
                                className="MsoNormal"
                                style={{
                                  textAlign: "justify",
                                  lineHeight: 1,
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                      <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{
                                          textAlign: "left",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          The Parties agree that the following
                                          Disputes are not subject to the above
                                          provisions concerning informal
                                          negotiations and binding arbitration:
                                          (a) any Disputes seeking to enforce or
                                          protect, or concerning the validity
                                          of, any of the intellectual property
                                          rights of a Party; (b) any Dispute
                                          related to, or arising from,
                                          allegations of theft, piracy, invasion
                                          of privacy, or unauthorized use; and
                                          (c) any claim for injunctive relief.
                                          If this provision is found to be
                                          illegal or unenforceable, then neither
                                          Party will elect to arbitrate any
                                          Dispute falling within that portion of
                                          this provision found to be illegal or
                                          unenforceable and such Dispute shall
                                          be decided by a court of competent
                                          jurisdiction within the courts listed
                                          for jurisdiction above, and the
                                          Parties agree to submit to the
                                          personal jurisdiction of that court.
                                        </span>
                                      </div>
                                    </bdt>
                                  </bdt>
                                  <bdt data-type="conditional-block">
                                    <bdt
                                      className="block-component"
                                      data-record-question-key="null"
                                      data-type="statement"
                                    >
                                      <span style={{ fontSize: "15px" }} />
                                    </bdt>
                                  </bdt>
                                </bdt>
                              </div>
                            </bdt>
                          </bdt>
                        </bdt>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              CORRECTIONS
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            There may be information on the Site that contains
                            typographical errors, inaccuracies, or omissions,
                            including descriptions, pricing, availability, and
                            various other information. We reserve the right to
                            correct any errors, inaccuracies, or omissions and
                            to change or update the information on the Site at
                            any time, without prior notice.
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              DISCLAIMER
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE
                            BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                            SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                            EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
                            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
                            WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                            COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF
                            ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME
                            NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
                            USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                            USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                            PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
                            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                            TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
                            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                            TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD
                            PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                            CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
                            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                            AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                            OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
                            THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
                            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER
                            OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO
                            OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                            PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                            PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                            OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              LIMITATIONS OF LIABILITY
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR
                            AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                            DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                            INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                            LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                            DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF
                            WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                            DAMAGES.{" "}
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="limitations_liability_option"
                                  data-type="statement"
                                />
                                <bdt data-type="body">
                                  NOTWITHSTANDING ANYTHING TO THE CONTRARY
                                  CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                                  CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
                                  THE ACTION, WILL AT ALL TIMES BE LIMITED TO{" "}
                                  <bdt
                                    className="block-container if"
                                    data-type="if"
                                    id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                                  >
                                    <bdt data-type="conditional-block">
                                      <bdt
                                        className="block-component"
                                        data-record-question-key="limitations_liability_option"
                                        data-type="statement"
                                      />
                                      <bdt data-type="body">
                                        THE AMOUNT PAID, IF ANY, BY YOU TO US
                                        <bdt
                                          className="block-container if"
                                          data-type="if"
                                          id="19e172cb-4ccf-1904-7c06-4251800ba748"
                                        >
                                          <bdt data-type="conditional-block">
                                            <bdt
                                              className="block-component"
                                              data-record-question-key="limilation_liability_time_option"
                                              data-type="statement"
                                            />
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                      <bdt data-type="conditional-block">
                                        <bdt
                                          className="block-component"
                                          data-record-question-key="limitations_liability_option"
                                          data-type="statement"
                                        />
                                      </bdt>
                                      . CERTAIN US STATE LAWS AND INTERNATIONAL
                                      LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                                      WARRANTIES OR THE EXCLUSION OR LIMITATION
                                      OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                                      YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS
                                      OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                                      YOU MAY HAVE ADDITIONAL RIGHTS.
                                    </bdt>
                                  </bdt>
                                  <bdt
                                    className="statement-end-if-in-editor"
                                    data-type="close"
                                  />
                                </bdt>
                              </bdt>
                            </bdt>
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              INDEMNIFICATION
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            You agree to defend, indemnify, and hold us
                            harmless, including our subsidiaries, affiliates,
                            and all of our respective officers, agents,
                            partners, and employees, from and against any loss,
                            damage, liability, claim, or demand, including
                            reasonable attorneys’ fees and expenses, made by any
                            third party due to or arising out of:{" "}
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="475fffa5-05ca-def8-ac88-f426b238903c"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_post_content_option"
                                  data-type="statement"
                                />
                              </bdt>
                              (<span style={{ fontSize: "14.6667px" }}>1</span>)
                              use of the Site; (
                              <span style={{ fontSize: "14.6667px" }}>2</span>)
                              breach of these Terms of Use; (
                              <span style={{ fontSize: "14.6667px" }}>3</span>)
                              any breach of your representations and warranties
                              set forth in these Terms of Use; (
                              <span style={{ fontSize: "14.6667px" }}>4</span>)
                              your violation of the rights of a third party,
                              including but not limited to intellectual property
                              rights; or (
                              <span style={{ fontSize: "14.6667px" }}>5</span>)
                              any overt harmful act toward any other user of the
                              Site with whom you connected via the Site.
                              Notwithstanding the foregoing, we reserve the
                              right, at your expense, to assume the exclusive
                              defense and control of any matter for which you
                              are required to indemnify us, and you agree to
                              cooperate, at your expense, with our defense of
                              such claims. We will use reasonable efforts to
                              notify you of any such claim, action, or
                              proceeding which is subject to this
                              indemnification upon becoming aware of it.
                            </bdt>
                          </span>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              USER DATA
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            We will maintain certain data that you transmit to
                            the Site for the purpose of managing the performance
                            of the Site, as well as data relating to your use of
                            the Site. Although we perform regular routine
                            backups of data, you are solely responsible for all
                            data that you transmit or that relates to any
                            activity you have undertaken using the Site. You
                            agree that we shall have no liability to you for any
                            loss or corruption of any such data, and you hereby
                            waive any right of action against us arising from
                            any such loss or corruption of such data.
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                              SIGNATURES
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            Visiting the Site, sending us emails, and completing
                            online forms constitute electronic communications.
                            You consent to receive electronic communications,
                            and you agree that all agreements, notices,
                            disclosures, and other communications we provide to
                            you electronically, via email and on the Site,
                            satisfy any legal requirement that such
                            communication be in writing. YOU HEREBY AGREE TO THE
                            USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
                            OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                            NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                            INITIATED OR COMPLETED BY US OR VIA THE SITE. You
                            hereby waive any rights or requirements under any
                            statutes, regulations, rules, ordinances, or other
                            laws in any jurisdiction which require an original
                            signature or delivery or retention of non-electronic
                            records, or to payments or the granting of credits
                            by any means other than electronic means.&nbsp;
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <bdt className="block-component" />
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "115%" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              CALIFORNIA USERS AND RESIDENTS
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            If any complaint with us is not satisfactorily
                            resolved, you can contact the Complaint Assistance
                            Unit of the Division of Consumer Services of the
                            California Department of Consumer Affairs in writing
                            at 1625 North Market Blvd., Suite N 112, Sacramento,
                            California 95834 or by telephone at (800) 952-5210
                            or (916) 445-1254.
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <span
                              style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166,
                              }}
                            >
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "115%" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              MISCELLANEOUS
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            These Terms of Use and any policies or operating
                            rules posted by us on the Site or in respect to the
                            Site constitute the entire agreement and
                            understanding between you and us. Our failure to
                            exercise or enforce any right or provision of these
                            Terms of Use shall not operate as a waiver of such
                            right or provision. These Terms of Use operate to
                            the fullest extent permissible by law. We may assign
                            any or all of our rights and obligations to others
                            at any time. We shall not be responsible or liable
                            for any loss, damage, delay, or failure to act
                            caused by any cause beyond our reasonable control.
                            If any provision or part of a provision of these
                            Terms of Use is determined to be unlawful, void, or
                            unenforceable, that provision or part of the
                            provision is deemed severable from these Terms of
                            Use and does not affect the validity and
                            enforceability of any remaining provisions. There is
                            no joint venture, partnership, employment or agency
                            relationship created between you and us as a result
                            of these Terms of Use or use of the Site. You agree
                            that these Terms of Use will not be construed
                            against us by virtue of having drafted them. You
                            hereby waive any and all defenses you may have based
                            on the electronic form of these Terms of Use and the
                            lack of signing by the parties hereto to execute
                            these Terms of Use.
                            <bdt className="block-component" />
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          style={{ lineHeight: "1.5" }}
                        >
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                              }}
                            >
                              <span style={{ fontSize: "19px" }}>
                                CONTACT US
                              </span>
                              &nbsp;
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            In order to resolve a complaint regarding the Site
                            or to receive further information regarding use of
                            the Site, please contact us at:&nbsp;
                          </span>
                        </div>
                      </div>
                      <div
                        align="center"
                        style={{ textAlign: "left", lineHeight: 1 }}
                      >
                        <br />
                      </div>
                      <div align="center" style={{ textAlign: "left" }}>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="8a6919c4-2010-e7d6-2305-d74dfb08909d"
                              data-type="question"
                            >
                              <strong>Live Sloka</strong>
                            </bdt>
                          </span>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="question">
                                  2-164 Kesava Reddy Nagar Colony{" "}
                                </bdt>
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="question">Meerpet</bdt>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="question">hyderabad</bdt>
                                <bdt className="block-component" />,{" "}
                                <bdt className="question">telangana</bdt>
                              </span>
                            </strong>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </strong>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>{" "}
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="question">500097</bdt>
                                <bdt className="statement-end-if-in-editor" />{" "}
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="block-component" />
                                <bdt className="question">India</bdt>
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                Phone:&nbsp;
                              </span>
                            </strong>
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                <span
                                  style={{
                                    lineHeight: "115%",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <bdt
                                    className="block-container question question-in-editor"
                                    data-id="dd6f266f-438b-bfdc-9204-0b17e109e270"
                                    data-type="question"
                                  >
                                    +19542571859
                                  </bdt>
                                </span>
                              </span>
                            </strong>
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            <strong>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="fdc2b5b8-c95f-244b-23a7-287f82541348"
                              data-type="question"
                            >
                              <strong>info@livesloka.com</strong>
                            </bdt>
                          </span>
                        </div>
                        <br />
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </Container>
      {/* </div>
      </div> */}

      <Footer />
    </>
  );
};

export default Tos;
