import { Card, Container, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import getWindowDimensions from "../helpers/useDimensions";
import { makeStyles } from "@material-ui/core/styles";
import "./Nav.css";
import Footer from "./Footer";
import { teachersData } from "./teachersData";
import Headernew from "../Components/Headernew";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  card: {
    height: 230,
    width: 400,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    cursor: "pointer",
    padding: 35,
    [theme.breakpoints.down("xs")]: {
      width: 350,
      height: 230,
      marginBottom: 20,
    },
  },

  aboutText: {
    color: "#636e72",
    marginTop: 20,
  },
  logoImg: {
    height: 100,
    width: 100,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logoText: {
    fontWeight: "bold",
    fontSize: 35,
    marginBottom: 5,
    color: "white",
    textShadow: "5px 5px 19px rgba(53, 53, 53, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
  backgroundContainer: {
    background: "#E3E7F0",
  },
}));
const AllTeachers = () => {
  const history = useHistory();
  const { width } = getWindowDimensions();

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Headernew />

      <div className={classes.backgroundContainer}>
        <Container
          style={{
            paddingTop: 30,
          }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            {teachersData.map((data) => (
              <Grid
                item
                sm={6}
                md={4}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Card
                  // onClick={() =>
                  //   history.push(`/teacher-profile?teacherName=${data.name}`)
                  // }
                  className={classes.card}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      style={{
                        height: 100,
                        width: 100,
                        objectFit: "contain",
                        borderRadius: 100 / 2,
                      }}
                      src={data?.image}
                      alt=""
                    />

                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 16,
                          textTransform: "uppercase",
                        }}
                      >
                        {data.name}
                      </p>
                    </div>
                  </div>
                  <p
                    className={classes.aboutText}
                    style={{ textAlign: "center", marginTop: 1 }}
                  >
                    {data.desc}
                  </p>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default AllTeachers;
