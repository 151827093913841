import React from "react";
import { makeStyles, Avatar } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Facebook } from "react-feather";
const TestimonialCard = ({ item, desktop }) => {
  const useStyles = makeStyles((theme) => ({
    cardContainer: {
      height: desktop ? 300 : "100%",
      width: "90%",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      borderRadius: 10,
      padding: 20,
      margin: 30,
    },
    firstDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    name: {
      fontSize: 16,
      fontWeight: 700,
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <div className={classes.firstDiv}>
        <div style={{ display: "flex" }}>
          <div>
            <Avatar
              style={{
                height: 50,
                width: 50,
                marginRight: 20,
              }}
              src={item?.image}
            />
          </div>

          <div>
            <p className={classes.name}>{item?.name}</p>
            <Rating style={{ marginLeft: -4 }} value={5} disabled />
            <p style={{ color: "#34495e", fontWeight: 600, marginTop: -5 }}>
              {item?.date}
            </p>
          </div>
        </div>

        <a target="_blank" href={item.link} rel="noreferrer">
          <div
            style={{
              height: 40,
              width: 40,
              backgroundColor: "#3867d6",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Facebook style={{ color: "white" }} />
          </div>
        </a>
      </div>

      <div>
        <p style={{ marginTop: 5 }}>{item?.desc}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
