import React from "react";
import Dance from "../assets/Designed/Dance.jpg";
import language from "../assets/Designed/language.jpg";
import Music from "../assets/Designed/Music.jpg";
import piano from "../assets/Designed/piano.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import MobileDance from "../assets/Designed/Mobile/Dance.jpg";
import MobileLanguage from "../assets/Designed/Mobile/language.jpg";
import MobileMusic from "../assets/Designed/Mobile/Music.jpg";
import MobilePiano from "../assets/Designed/Mobile/piano.jpg";
import useWindowDimensions from "../helpers/useDimensions";
import { useHistory } from "react-router-dom";
const Slidershow = () => {
  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <div>
      {width > 500 ? (
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          showArrows={true}
          showThumbs={false}
        >
          <div onClick={() => history.push("/booking")}>
            <img src={Dance} alt="Dance" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={piano} alt="Piano" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={Music} alt="Music" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={language} alt="Language" />
          </div>
        </Carousel>
      ) : (
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          showArrows={true}
          showThumbs={false}
        >
          <div onClick={() => history.push("/booking")}>
            <img src={MobileDance} alt="Mobile" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={MobilePiano} alt="Piano" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={MobileMusic} alt="Music" />
          </div>
          <div onClick={() => history.push("/booking")}>
            <img src={MobileLanguage} alt="Language" />
          </div>
        </Carousel>
      )}
    </div>
  );
};

export default Slidershow;
