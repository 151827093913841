import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { ArrowRight } from "react-feather";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Link, useLocation } from "react-router-dom";
import facebookLogo from "../assets/facebook.svg";
import insta from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";
import whatsapp from "../assets/whatsapp.svg";
import LiveSlokaLogo from "../assets/livesloka_logo.png";
const useStyles = makeStyles((theme) => ({
  footerContainer: {
    minHeight: "300px",
    backgroundColor: "white",
    boxShadow: "0px -1px 5px 0px rgba(110,110,110,0.75)",
  },
  footerTitle: {
    fontSize: 30,
    fontWeight: 700,
  },
  subTitle: {
    color: "#0984e3",
    textTransform: "uppercase",
  },
  footerDesc: {
    width: "80%",
    fontWeight: 400,
    color: "#999",
    marginTop: 20,
  },
  socialIcon: {
    height: 40,
    width: 40,
    borderRadius: 25,
    backgroundColor: "#3867d6",
    marginTop: 20,
    marginRight: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  IconInside: {
    color: "white",
    height: 20,
    width: 20,
  },
  exploteText: {
    fontSize: 20,
    fontWeight: 700,
  },
  footerLink: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    textDecoration: "none",
    color: "black",
  },
  footerIcon: {
    height: 20,
    width: 20,
    marginRight: 5,
    color: "#3498db",
  },
  footerTextLink: {
    cursor: "pointer",
    opacity: 0.7,
    "&:hover": {
      color: "#3867d6",
    },
  },
  contactFooter: {
    [theme.breakpoints.down("xs")]: {
      marginRight: 20,
    },
  },

  iconSize: {
    height: 35,
    width: 35,
    marginLeft: 5,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Grid container>
          <Grid
            item
            sm={12}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: -20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                style={{
                  height: 150,
                  width: 150,
                  objectFit: "contain",
                  marginBottom: 10,
                }}
                src={LiveSlokaLogo}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/LiveSloka/"
                >
                  <img className={classes.iconSize} src={facebookLogo} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/livesloka/"
                >
                  <img className={classes.iconSize} src={insta} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/livesloka"
                >
                  <img className={classes.iconSize} src={twitter} alt="" />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtube.com/channel/UCE4EQUGtAq9eQug0vqkE6cA"
                >
                  <img
                    className={classes.iconSize}
                    style={{ height: 38, width: 38, marginTop: -2 }}
                    src={youtube}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </Grid>

          <Grid
            item
            sm={12}
            md={3}
            style={{ marginTop: 20 }}
            className={classes.contactFooter}
          >
            <div>
              <p className={classes.exploteText}>Contact</p>

              <div style={{ marginTop: 10 }}>
                <div className={classes.footerLink}>
                  <a href={`https://api.whatsapp.com/send?phone=19542571859`}>
                    {/* <WhatsAppIcon
                      className={classes.footerIcon}
                      style={{ color: "#2ecc71" }}
                    /> */}

                    <img
                      src={whatsapp}
                      style={{ height: 20, width: 20, marginRight: 5 }}
                      alt=""
                    />
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?phone=918309341208`}
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerTextLink}>+91 830-9341208</p>
                  </a>
                </div>
                <div className={classes.footerLink}>
                  <a href={`https://api.whatsapp.com/send?phone=918328530889`}>
                    <img
                      src={whatsapp}
                      style={{ height: 20, width: 20, marginRight: 5 }}
                      alt=""
                    />
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?phone=19542572313`}
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerTextLink}>+91 832-8530889</p>
                  </a>
                </div>
                <div className={classes.footerLink}>
                  <a href={`https://api.whatsapp.com/send?phone=918074474524`}>
                    {" "}
                    <img
                      src={whatsapp}
                      style={{ height: 20, width: 20, marginRight: 5 }}
                      alt=""
                    />
                  </a>{" "}
                  <a
                    href={`https://api.whatsapp.com/send?phone=918074474524`}
                    style={{ textDecoration: "none" }}
                  >
                    <p className={classes.footerTextLink}>+91 8074474524</p>
                  </a>
                </div>
                <div className={classes.footerLink}>
                  <a href={"mailto:info@livesloka.com"}>
                    {" "}
                    <MailOutlineIcon
                      className={classes.footerIcon}
                      style={{ color: "#0984e3" }}
                    />
                  </a>{" "}
                  <p className={classes.footerTextLink}>info@livesloka.com</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item sm={12} md={3} style={{ marginTop: 20 }}>
            <div>
              <p className={classes.exploteText}>Explore</p>

              <div>
                <div className={classes.footerLink}>
                  <Link to="/about" className={classes.footerLink}>
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>About</p>
                  </Link>
                </div>
                <Link to="/contact" className={classes.footerLink}>
                  <div className={classes.footerLink}>
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>Book a Demo</p>
                  </div>
                </Link>
                <div className={classes.footerLink}>
                  <Link to="/teachers" className={classes.footerLink}>
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>Teacher</p>
                  </Link>
                </div>

                <div className={classes.footerLink}>
                  <Link to="/apply" className={classes.footerLink}>
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>Careers</p>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item sm={12} md={3} style={{ marginTop: 20 }}>
            <div>
              <p className={classes.exploteText}>Quick Links</p>

              <div>
                <div className={classes.footerLink}>
                  <Link className={classes.footerLink} to="/privacy">
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>Privacy Policy</p>
                  </Link>
                </div>
                <div className={classes.footerLink}>
                  <Link className={classes.footerLink} to="tos">
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>
                      Terms and Conditions
                    </p>
                  </Link>
                </div>
                <div className={classes.footerLink}>
                  <Link className={classes.footerLink} to="/refund">
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>
                      Cancellation and Refund Policy
                    </p>
                  </Link>
                </div>
                <div className={classes.footerLink}>
                  <Link className={classes.footerLink} to="/cookie">
                    <ArrowRight className={classes.footerIcon} />{" "}
                    <p className={classes.footerTextLink}>Cookie Policy</p>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div
        style={{
          width: "100%",
          height: 40,
          backgroundColor: "#ecf0f1",
          marginTop: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: 14, opacity: 0.9 }}>
          © <span style={{ fontSize: 12 }}> 2021 </span>{" "}
          <Link to="/" style={{ textDecoration: "none" }}>
            Live Sloka International Pvt, Ltd.
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
