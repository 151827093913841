import React from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import { Badge } from '@material-ui/core';
const BotButton = ({ showBot, alert }) => {
  return (
    <div
      id="chat-circle"
      onClick={(e) => {
        e.stopPropagation();

        showBot(true);
      }}
    >
      <Badge badgeContent={alert} color="error">
        <ChatIcon style={{ fontSize: 30 }} />
      </Badge>
    </div>
  );
};

export default BotButton;
