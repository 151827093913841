const url = window.location.href;

const subjectData = [
  {
    id: 9,
    image: `${url}/assets/sloka.jpeg`,
    chipName: "Slokas",
    about:
      "The best of Sloka teachers to connect you back to essence of chants",
    desc: "Sloka classes for kids and adults.",
    color: "#718093",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Savitri",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FSavitri%20Sloka.jpg?alt=media&token=f7be6f94-7e3d-460c-823e-ec4fa3672901",
      },

      {
        id: 2,
        teacherName: "Gopinathacharyulu",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FGopi%20Sloka.jpg?alt=media&token=d243cc95-c536-4726-ae7a-e3770769a071",
      },
    ],
  },
  {
    id: 1,
    image: `${url}/assets/Bharatanatyam.jpeg`,
    chipName: "Bharatanatyam",
    about: "Learn Bharatnatyam from dedicated teachers",
    desc: "Bharatanatyam Classes at affordable prices for kids and adults.",
    color: "#0984e3",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],
    instructors: [
      {
        id: 1,
        teacherName: "Jill Mehta",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FJill%20Bharatanatyam.jpeg?alt=media&token=22d33aa5-8ea3-4cf7-834d-3b182a908eb0",
      },
      {
        id: 2,
        teacherName: "Amritha",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FAmritha%20Bharatanatyam.jpg?alt=media&token=a3d8e244-4fa1-4b98-8eb9-6fbdd339f67c",
      },
      {
        id: 2,
        teacherName: "Aishwarya",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FAishwarya%20Bharatanatyam.jpeg?alt=media&token=2f47916e-ef1c-4466-9e55-2ef2d4b42022",
      },
      {
        id: 4,
        teacherName: "Sajini",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FSajini%20Bharatanatyam.jpeg?alt=media&token=fbfd9195-e1ec-48ff-af02-d33a079cf368",
      },
    ],
  },

  {
    id: 2,
    image: `${url}/assets/Hindustani.jpeg`,
    chipName: "Hindustani Music",
    about: "Hindustani Music classes from gifted Artists",
    desc: "Hindustani music classes for kids and adults.",
    color: "rgb(243, 156, 18)",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Subhadip",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FSubhadip%20Hindustani.jpeg?alt=media&token=1604d348-4100-41a6-8bcd-6635679be2a8",
      },
    ],
  },

  {
    id: 3,
    image: `${url}/assets/Tollywood.jpeg`,
    chipName: "Tollywood Dance",
    about: "Teachers with real choreography experience",
    desc: "Amazing activity for kids and adults (also parents :)",
    color: "#2ecc71",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Pradeep",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FPradeep%20Tollywood.jpeg?alt=media&token=bd164004-f650-4284-95ab-defc4c1b61d7",
      },
    ],
  },

  {
    id: 4,
    image: `${url}/assets/Bollywood.jpeg`,
    chipName: "Bollywood Dance",
    about: "Teachers with real choreography experience",
    desc: "Amazing activity for kids and adults (also parents :)",
    color: "rgb(231, 76, 60)",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Ganesh",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FGanesh%20Bollywood.jpeg?alt=media&token=7fcbc27c-0a35-4660-aa06-1ab9321f86e9",
      },
    ],
  },

  {
    id: 5,
    image: `${url}/assets/Piano.jpeg`,
    chipName: "Piano | Keyboard",
    about: "Our Instrument teachers are from music industry itself",
    desc: "Learning instruments is a joy that cannot be missed by kids or adults",
    color: "#A52A2A",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Soloman Raj",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FRaj%20Piano.jpeg?alt=media&token=ccf8e186-0ae6-4c6d-a3c9-ce006d5096ea",
      },
    ],
  },

  {
    id: 6,
    image: `${url}/assets/Kathak.jpeg`,
    chipName: "Kathak",
    about: "Learn Kathak from our excellent teachers. ",
    desc: "Our teachers are all Masters in Kathak from reputed universities.",
    color: "#2980b9",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Prerana Kathak",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FPrerana%20Kathak.jpeg?alt=media&token=3fc76424-6ea1-408d-84a2-61ed421c76d0",
      },
    ],
  },

  {
    id: 7,
    image: `${url}/assets/Carnatic.jpeg`,
    chipName: "Carnatic Music",
    about: "Carnatic Music classes from the finest trained teachers",
    desc: "Carnatic music classes for kids and adults.",
    color: "#e84393",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Keerthana",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FKeerthana%20Carnatic%20music.jpeg?alt=media&token=155bcb48-296c-45aa-9b09-7308d3ef1dba",
      },
    ],
  },

  {
    id: 8,
    image: `${url}/assets/Sandalwood.jpeg`,
    chipName: "Sandalwood dance",
    about: "Yes! We have teachers who can make you dance for Kannada Songs",
    desc: "Sandalwood dance classes for kids and adults.",
    color: "#d63031",
    timings: ["Morning and Evenings Timings : EST, CST, MST, PST"],
    classStructure: [
      "Two group classes a week",
      "Each class is of 40 mins duration",
      "All the classes happen on Live Sloka App",
      "Powered by Zoom",
    ],

    instructors: [
      {
        id: 1,
        teacherName: "Sanju",
        image:
          "https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/TeacherImages%2FSanju%20Tollywood.jpeg?alt=media&token=7b64680e-9c6f-401d-b02a-9e675f119fb8",
      },
    ],
  },
];

export default subjectData;
