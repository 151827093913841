import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { User, Users, Calendar, BookOpen } from "react-feather";
const useStyles = makeStyles((theme) => ({
  achievementContainer: {
    height: 250,
    width: "100%",
    backgroundImage:
      "url(https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
  overlay: {
    height: 250,
    width: "100%",
    background: "rgba(0, 0, 0, 0.700)",
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },

  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 250,
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
  cont: {
    display: "flex",
    alignItems: "center",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 25,
    },
  },
  icon: {
    height: 70,
    width: 70,
    [theme.breakpoints.down("xs")]: {
      height: 40,
      width: 40,
    },
  },
  num: {
    fontSize: 40,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  subText: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },

  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const Achievement = () => {
  const classes = useStyles();
  return (
    <div className={classes.achievementContainer}>
      <div className={classes.overlay}>
        <Container className={classes.mainContainer}>
          <Grid container>
            <Grid item xs={6} md={3} className={classes.gridCenter}>
              <div className={classes.cont}>
                <User className={classes.icon} />
                <div style={{ marginLeft: 10 }}>
                  <p className={classes.num}>300</p>
                  <p className={classes.subText}>Students</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={3} className={classes.gridCenter}>
              <div className={classes.cont}>
                <Users className={classes.icon} />
                <div style={{ marginLeft: 10 }}>
                  <p className={classes.num}>35</p>
                  <p className={classes.subText}>Teachers</p>
                </div>
              </div>
            </Grid>

            <Grid item xs={6} md={3} className={classes.gridCenter}>
              <div className={classes.cont}>
                <Calendar className={classes.icon} />
                <div style={{ marginLeft: 10 }}>
                  <p className={classes.num}>30,000</p>
                  <p className={classes.subText}>Classes</p>
                </div>
              </div>
            </Grid>

            <Grid item xs={6} md={3} className={classes.gridCenter}>
              <div className={classes.cont}>
                <BookOpen className={classes.icon} />
                <div style={{ marginLeft: 10 }}>
                  <p className={classes.num}>10+</p>
                  <p className={classes.subText}>Subjects</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Achievement;
