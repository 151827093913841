import React, { useState } from "react";
import { Form, Input, Select, Checkbox, Button, Radio } from "antd";
import io from "socket.io-client";
import api from "../helpers/api";
import SnackbarAlert from "../Components/SnackbarAlert";
// import "antd/dist/antd.css";
const { Option } = Select;
const socket = io(process.env.REACT_APP_API_KEY);

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AntForm = ({ getSubjects, timeZones }) => {
  const [form] = Form.useForm();
  const [subjectChecked, setSubjectChecked] = useState([]);
  const [value, setValue] = React.useState("Male");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertMessage, setalertMessage] = useState("");
  const onChange = (e) => {
    setValue(e.target.value);
  };

  let formRef = React.createRef();
  const onFinish = async (values) => {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      whatsAppnumber: `${values.prefix}${values.phone}`,
      timeZoneId: values.timezone,
      subjects: subjectChecked,
      age: values.age,
      gender: values.gender,
    };
    try {
      const data = await api.post("/register/customer", formData);
      socket.emit("customer-submitted-form", { name: values.firstName });
      if (data.status === 200) {
        setAlertOpen(true);
        setAlertColor("success");
        setalertMessage(data?.data?.message);
        formRef.current.resetFields();
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        setAlertOpen(true);
        setAlertColor("error");
        setalertMessage(error?.response?.data?.error);
      }
    }
  };

  const prefixSelector = (
    <Form.Item
      name="prefix"
      noStyle
      rules={[
        {
          required: true,
          message: "Select country code",
        },
      ]}
    >
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="+1">+1</Option>
        <Option value="+91">+91</Option>
        <Option value="+61">+61</Option>
        <Option value="+65">+65</Option>
        <Option value="+44">+44</Option>
      </Select>
    </Form.Item>
  );

  let sortedSubjects = getSubjects?.sort(
    (a, b) => a.sortingOrder - b.sortingOrder
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        ref={formRef}
      >
        <Form.Item
          name="firstName"
          label="Student Name"
          rules={[
            {
              required: true,
              message: "Please input your First Name",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Parent Name"
          rules={[
            {
              required: true,
              message: "Please input your Parent Name",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="age"
          label="Student Age"
          fullWidth
          type="number"
          rules={[
            {
              required: true,
              message: "Please input your Age",
            },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
            {
              pattern: /^[\d]{0,2}$/,
              message: "Max 100",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          fullWidth
          rules={[
            {
              required: true,
              message: "Please select Gender",
            },
          ]}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="Male">Male</Radio>
            <Radio value="Female">Female</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label="WhatsApp Number"
          hasFeedback
          type="number"
          rules={[
            {
              required: true,
              message: "Please input your WhatsApp Number",
            },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
            {
              pattern: /^[\d]{10,10}$/,
              message: "Value should be less than 10 character",
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="timezone"
          label="Timezone"
          rules={[
            {
              required: true,
              message: "Please select timezone!",
            },
          ]}
        >
          <Select placeholder="Select Timezone">
            {timeZones &&
              timeZones.map((time) => (
                <Option key={time._id} value={time.id}>
                  {time.timeZoneName}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="subject" label="Subject">
          {sortedSubjects &&
            sortedSubjects.map((subject) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox
                  style={{ marginBottom: 5, marginTop: 5 }}
                  checked={subjectChecked.includes(subject.id)}
                  onChange={() =>
                    setSubjectChecked((prev) => {
                      let prevData = [...prev];
                      let str = subject.id;
                      if (prevData.includes(str)) {
                        let index = prevData.indexOf(str);
                        prevData.splice(index, 1);
                        return prevData;
                      } else {
                        return [...prevData, str];
                      }
                    })
                  }
                >
                  {subject.subjectName}
                </Checkbox>
              </div>
            ))}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" style={{ marginBottom: 20 }}>
            Register
          </Button>
        </Form.Item>
      </Form>

      <SnackbarAlert
        setOpen={setAlertOpen}
        open={alertOpen}
        message={alertMessage}
        color={alertColor}
      />
    </>
  );
};

export default AntForm;
