import { Box, Container } from "@material-ui/core";
import { makeStyles, CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState,useContext } from "react";
import loadingAnimation from "../assets/loading.json";
import "./toggle.css";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import api from "../helpers/api";
import Lottie from "react-lottie";
import moment from "moment";
import { Redirect, useParams, useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useWindowDimensions from "../helpers/useDimensions";
import SnackbarAlert from "../Components/SnackbarAlert";
import { BookOpen, Calendar, Clock } from "react-feather";
import { MapPin } from "react-feather";
import ReactGA from "react-ga";
import Headernew from "../Components/Headernew";
import { Form } from "antd";
import { useHistory } from 'react-router';
import { CountryContext } from "../Components/CountryContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div style={{ display: "flex" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100vh",
    width: "100%",
  },
  topHeader: {
    height: 70,
    boxShadow: "0px 4px 3px 0px rgba(189,195,199,0.75)",
  },
  specialText: {
    color: "#f39c12",
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  timezoneContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  preferredClass: { padding: 10 },
  toggleGroup: {
    margin: 20,
    height: 40,
  },
  secondToggle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textDay: {
    color: "#3867d6",
    fontSize: 15,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  timetoggle: {
    width: 110,
    height: 40,
    borderRadius: 10,
    margin: 5,
  },
  customChip: {
    height: 40,
    width: "fit-content",
    border: "1.8px solid #bdc3c7",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
  },

  activatedCustomChip: {
    height: 40,
    width: "fit-content",
    border: "1.55px solid #3867d6",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
    backgroundColor: "rgba(56, 103, 214, 0.2)",
  },

  timeZonesActivatedChip: {
    height: 40,
    width: "fit-content",
    border: "1.55px solid #27ae60",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
    backgroundColor: "#2ecc714a",
  },
  customText: {
    fontSize: 16,
    color: "#3867d6",
  },

  timezoneChip: {
    height: 80,
    width: 70,
    border: "1.8px solid #bdc3c7",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 10,
    cursor: "pointer",
  },

  activatedtimezoneChip: {
    height: 80,
    width: 70,
    border: "1.55px solid #3867d6",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 10,
    cursor: "pointer",
    backgroundColor: "rgba(56, 103, 214, 0.2)",
  },
  overlay: {
    background: "rgba(255, 255, 255, 0.9)",
    width: "100%",
  },
  catogeryCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  categoryClasses: {
    display: "flex",
    alignItems: "center",
  },
  categoryText: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    textAlign: "left",
  },
  subjectTitle: {
    fontSize: 20,
    color: "#59667d",
    "&:hover": {
      color: "#3a4352",
    },
  },

  subject: {
    color: "#128994",
    marginLeft: 10,
    marginRight: 10,
    fontSize: 15,
  },
  cardContainer: {
    marginTop: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    borderRadius: 10,
    maxWidth: "500px",
    paddingLeft: 10,
    paddingTop: 10,
  },
  tab1: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 50,
      width: "100%",
    },
  },
  maleActivatedChip: {
    height: 40,
    width: "fit-content",
    border: "1.55px solid #f0932b",
    padding: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    cursor: "pointer",
    backgroundColor: "#ffbe764a",
  },
  ActivatedavatarcustomChip: {
    backgroundColor: "#2ecc714a",
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const BookSlotNew = () => {
  const { width } = useWindowDimensions();
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const { country } = useContext(CountryContext)
  const [timeZones, setTimeZones] = useState([]);
  const [timezoneDates, setTimezoneDates] = useState([]);
  const [freetimeslots, setFreetimeslots] = useState([]);
  const [bookedSubjects, setBookedSubjects] = useState([]);
  const [categorySubjects, setCategorySubjects] = useState([]);
  const [PMSlots, setPMSlots] = useState([]);
  const [AMSlots, setAMSlots] = useState([]);

  const [activatedSubject, setActivatedSubject] = useState("");
  const [activatedDate, setActivatedDate] = useState({});
  const [activatedTimeslot, setActivatedTimeslot] = useState({});
  const [activatedTimezone, setActivatedTimezone] = useState({});
  const [activatedAMPM, setActivatedAMPM] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertMessage, setalertMessage] = useState("");
  const [needToRedirect, setNeedToRedirect] = useState(false);
  const [usersData, setUserData] = useState({});
  const [value, setValue] = useState(0);

  const history = useHistory();

  useEffect(() => {
    let userDetails = localStorage.getItem("user");
    setUserData(JSON.parse(userDetails));
  }, []);
  const getDatesByTimezone = useCallback(async () => {
    setLoadingDates(true);
    const data = await api.get(
      `/register/zone?timeZone=${activatedTimezone?.timeZoneName}`
    );
    setTimezoneDates(data?.data.result);
    setActivatedDate(data?.data.result[0]);
    setLoadingDates(false);
  }, [activatedTimezone]);

  const getTimeslots = useCallback(async () => {
    try {
      setLoading2(true);

      const formData = {
        date: activatedDate?.fullDate,
        zone: activatedTimezone?.timeZoneName,
        subject: activatedSubject,
      };
      if (activatedSubject && activatedDate && activatedDate) {
        const data = await api.post(`/register/slots`, formData);
        setFreetimeslots(data?.data.result);

        let pmSlots = [];
        let amSlots = [];
        if (data?.data.result) {
          data?.data.result.map((time) => {
            if (time.slotInTimezone.split(" ")[1] === "AM") {
              amSlots.push(time);
            } else {
              pmSlots.push(time);
            }
          });
        }

        setAMSlots(amSlots);
        setPMSlots(pmSlots);

        setLoading2(false);
      } else {
        setFreetimeslots([]);
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  }, [activatedDate, activatedSubject, activatedTimezone]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/register/demo/classes?id=${params.id}`)
      .then((data) => {
        setBookedSubjects(data?.data.result);
        setNeedToRedirect(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNeedToRedirect(true);
        setLoading(false);
      });
  }, [params.id]);

  const fetchTimeZones = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.get(`/admin/get/timeZone`);
      if (data?.data?.result) {
        let { result } = data.data;
        result = result.filter((timeZone) => timeZone.timeZonePriority);
        setTimeZones(result);
        setActivatedTimezone(location?.state?.selectedTimezone);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [location]);

  useEffect(() => {
    fetchTimeZones();
    (async () => {
    try {
      const data = await api.get(`/admin/subject/${country}`);

      setActivatedSubject("");
      setActivatedTimeslot({});
      setActivatedDate({});
      setActivatedTimezone({});
      const sorted = data?.data.result.sort((a, b) =>
        a.category.categoryName.localeCompare(b.category.categoryName)
      );
      setCategorySubjects(sorted);
    } catch (error) {
      console.log(error);
    }
  })()
  }, [fetchTimeZones,country]);

  useEffect(() => {
    if (activatedTimezone && Object.keys(activatedTimezone).length) {
      getDatesByTimezone();
    }
  }, [activatedTimezone, getDatesByTimezone]);

  useEffect(() => {
    if (
      activatedTimezone &&
      Object.keys(activatedTimezone).length &&
      Object.keys(activatedDate).length &&
      activatedSubject
    ) {
      getTimeslots();
    } else {
      setFreetimeslots([]);
      setLoading2(false);
    }
  }, [activatedDate, getTimeslots, activatedTimezone, activatedSubject]);

  const submitForm = async (values) => {
    setBtnLoading(true);

    if (
      activatedTimeslot === undefined ||
      activatedSubject === undefined ||
      activatedDate === undefined
    ) {
      setAlertOpen(true);
      setAlertColor("error");
      setalertMessage("Select all the fields");
    } else if (activatedTimezone === undefined) {
      setAlertOpen(true);
      setAlertColor("error");
      setalertMessage("Please select timezone");
    } else {
      const formData = {
        slot: activatedTimeslot.slot,
        subject: activatedSubject,
        startDate: activatedDate.fullDate,
        timeZone: activatedTimezone.id,
        customerId: params.id,
        time: activatedTimeslot.slotInTimezone,
      };

      try {
        const data = await api.post("/register", formData);

        if (data.status === 200) {
          ReactGA.event({
            category: "Slot Booking",
            action: "Slot booking success",
            label: `${usersData?.name} ${usersData?.prefix || "+1"} ${
              usersData?.phone
            }`,
          });
          history.push(`/moreinfo/${params.id}`,{userId:data.data.userId})
          return
        } else {
          setAlertOpen(true);
          setAlertColor("error");
          setalertMessage("Invalid submission");     
        }
      } catch (error) {
        console.log(error.response);
        setAlertOpen(true);
        setAlertColor("error");
        setalertMessage(error?.response?.data.error || "Invalid submission");
        ReactGA.event({
          category: "Slot Booking",
          action: error?.response?.data.error,
          label: `${usersData?.name} ${usersData?.prefix || "+1"} ${
            usersData?.phone
          }`,
        });
      }
    }

    setBtnLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (needToRedirect) {
    return <Redirect to="/booking" />;
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Lottie options={defaultOptions} height={100} width={100} />;
      </div>
    );
  }

  return (
    <>
      <div>
        <SnackbarAlert
          setOpen={setAlertOpen}
          open={alertOpen}
          message={alertMessage}
          color={alertColor}
        />
        <Headernew />
        <div className={classes.container}>
          <div className={classes.topHeader}>
            <p
              style={{
                textAlign: "center",
                fontSize: 18,
                marginTop: 20,
                fontWeight: 600,
              }}
            >
              Schedule Your FREE{" "}
              <span className={classes.specialText}>Demo </span>
              Class
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: 18,
                marginTop: 5,
                fontWeight: 600,
              }}
            >
              <span className={classes.specialText}>Limited Spots Left !!</span>
            </p>
          </div>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={classes.cardContainer}>
              <Form
                onFinish={submitForm}
                style={{ width: "100%", paddingBottom: 20 }}
              >
                <div className={classes.preferredClass}>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MapPin style={{ height: 20, widht: 20, marginRight: 5 }} />{" "}
                    Select a Timezone
                  </p>
                  <Grid container>
                    {timeZones &&
                      timeZones.map((time) => (
                        <div
                          key={time.id}
                          onClick={() => {
                            setActivatedTimezone(time);
                          }}
                          className={
                            activatedTimezone?.id === time.id
                              ? classes.timeZonesActivatedChip
                              : classes.customChip
                          }
                          style={{ width: 70 }}
                        >
                          <p className={classes.customText}>
                            {time.timeZoneName}
                          </p>
                        </div>
                      ))}
                  </Grid>
                </div>

                {(activatedTimezone &&
                  Object.keys(activatedTimezone).length === 0) ||
                activatedTimezone === undefined ? null : (
                  <>
                    <div className={classes.preferredClass}>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BookOpen
                          style={{ height: 20, widht: 20, marginRight: 5 }}
                        />{" "}
                        Select a subject
                      </p>
                      <Grid container={width > 700 ? true : false}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Tabs
                            variant="scrollable"
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: "#3867d6",
                              },
                            }}
                            value={value}
                            onChange={handleChange}
                            scrollButtons="auto"
                          >
                            {categorySubjects &&
                              categorySubjects.map((item, i) => (
                                <Tab
                                  style={{
                                    minWidth: width > 700 ? 100 : 0,
                                  }}
                                  label={
                                    <p style={{ textTransform: "none" }}>
                                      {item.category.categoryName}
                                    </p>
                                  }
                                  {...a11yProps(i)}
                                />
                              ))}
                          </Tabs>
                        </div>

                        {categorySubjects.map((item, i) => {
                          return (
                            <div style={{}}>
                              <TabPanel
                                style={{ display: "flex" }}
                                value={value}
                                index={i}
                              >
                                <Grid container>
                                  {item.subjects.map((item) => {
                                    return (
                                      <>
                                        <div
                                          key={item.id}
                                          className={
                                            activatedSubject === item.id
                                              ? classes.activatedCustomChip
                                              : classes.customChip
                                          }
                                          style={{
                                            background:
                                              bookedSubjects?.includes(item._id)
                                                ? "#dddddd"
                                                : "",
                                            marginLeft: width > 600 ? 0 : -0,
                                          }}
                                        >
                                          <p
                                            onClick={() => {
                                              if (
                                                !bookedSubjects?.includes(
                                                  item._id
                                                )
                                              ) {
                                                setActivatedSubject(item.id);
                                              }
                                            }}
                                            className={classes.customText}
                                          >
                                            {item.subjectName.split(" ")[0]}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Grid>
                              </TabPanel>
                            </div>
                          );
                        })}
                      </Grid>
                    </div>

                    {activatedSubject && (
                      <>
                        {loadingDates ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <>
                            <div className={classes.preferredClass}>
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Calendar
                                  style={{
                                    height: 20,
                                    widht: 20,
                                    marginRight: 5,
                                  }}
                                />{" "}
                                Select a Date
                              </p>

                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {timezoneDates &&
                                  timezoneDates.map((date) => (
                                    <div
                                      key={date.fullDate}
                                      onClick={() => {
                                        setActivatedDate(date);
                                      }}
                                      className={
                                        activatedDate?.fullDate ===
                                        date.fullDate
                                          ? classes.activatedtimezoneChip
                                          : classes.timezoneChip
                                      }
                                    >
                                      <p className={classes.textDay}>
                                        {date.day}
                                      </p>
                                      <p className="textDate">{date.date}</p>
                                      <p>{date.month}</p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            {loading2 ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 20,
                                  marginBottom: 20,
                                }}
                              >
                                <CircularProgress />
                              </div>
                            ) : (
                              <>
                                {freetimeslots && (
                                  <div className={classes.preferredClass}>
                                    <p
                                      style={{
                                        fontSize: 18,
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Clock
                                        style={{
                                          height: 20,
                                          widht: 20,
                                          marginRight: 5,
                                        }}
                                      />{" "}
                                      Select a time for
                                      <span
                                        className={classes.specialText}
                                        style={{ marginLeft: 3 }}
                                      >
                                        {`${activatedDate.month} ${
                                          activatedDate.date
                                        }, ${moment(
                                          activatedDate.fullDate
                                        ).format("YYYY")}`}
                                      </span>
                                    </p>

                                    <div
                                      style={{
                                        marginTop: 10,
                                        marginLeft: 15,
                                        marginBottom: 10,
                                      }}
                                    >
                                      <Button
                                        onClick={() => setActivatedAMPM(true)}
                                        style={{
                                          height: 30,
                                          widht: 50,
                                          border: activatedAMPM
                                            ? "1px solid #3867d6"
                                            : "none",
                                        }}
                                      >
                                        Morning
                                      </Button>
                                      <Button
                                        onClick={() => setActivatedAMPM(false)}
                                        style={{
                                          height: 30,
                                          widht: 50,
                                          marginLeft: 20,
                                          border: activatedAMPM
                                            ? "none"
                                            : "1px solid #3867d6",
                                        }}
                                      >
                                        Afternoon
                                      </Button>
                                    </div>

                                    {activatedAMPM ? (
                                      <Grid container>
                                        {AMSlots &&
                                          AMSlots.map((time) => (
                                            <div
                                              onClick={() =>
                                                setActivatedTimeslot(time)
                                              }
                                              className={
                                                activatedTimeslot?.slot ===
                                                time.slot
                                                  ? classes.activatedCustomChip
                                                  : classes.customChip
                                              }
                                            >
                                              <p className={classes.customText}>
                                                {time.slotInTimezone}
                                              </p>
                                            </div>
                                          ))}
                                      </Grid>
                                    ) : (
                                      <Grid container>
                                        {PMSlots &&
                                          PMSlots.map((time) => (
                                            <div
                                              onClick={() =>
                                                setActivatedTimeslot(time)
                                              }
                                              className={
                                                activatedTimeslot?.slot ===
                                                time.slot
                                                  ? classes.activatedCustomChip
                                                  : classes.customChip
                                              }
                                            >
                                              <p className={classes.customText}>
                                                {time.slotInTimezone}
                                              </p>
                                            </div>
                                          ))}
                                      </Grid>
                                    )}

                                    {activatedSubject &&
                                    freetimeslots &&
                                    freetimeslots.length === 0 ? (
                                      <p
                                        style={{
                                          textAlign: "left",
                                          fontSize: 18,
                                          marginTop: 10,
                                          marginLeft: 10,
                                        }}
                                      >
                                        All time slots are booked for specified
                                        date
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          textAlign: "center",
                                          color: "#bdc3c7",
                                        }}
                                      >
                                        Due to high demand, it's difficult to
                                        reschedule a demo class, Please pick
                                        your slot carefully.
                                      </p>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disabled={btnLoading}
                    type="submit"
                    className="booking-button"
                    variant="contained"
                    color="primary"
                  >
                    {btnLoading ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "confirm slot"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default BookSlotNew;
