import Footer from "../HomeLanding/Footer";
import React, { useEffect, useContext, useState } from "react";
import Headernew from "../Components/Headernew";
import Achievement from "../HomeLanding/Achievement";
import Countries from "../HomeLanding/Countries";
import CategorySubjects from "./CategorySubjects";
import Slidershow from "./Slidershow";
import Testimonial from "./Testimonial";
import AboutTeacher from "./AboutTeacher";
import { useHistory, useLocation } from "react-router-dom";
import { CountryContext } from "./../Components/CountryContext";
import BookingDialog from "../Components/BookingDialog";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { country } = useContext(CountryContext);

  useEffect(() => {
    if (country) {
      history.push(`/country/${country}`);
    }
  }, [country, history]);

  // useEffect(() => {
  //   // if (
  //   //   location.pathname === "/booking" ||
  //   //   location.pathname === "/bookademo"
  //   // ) {
  //   //   setOpen(true);
  //   // } else {
  //   //   const timer = setTimeout(() => {
  //   //     setOpen(true);
  //   //   }, 3000);

  //   //   return () => clearTimeout(timer);
  //   // }

  //   const timer = setTimeout(() => {
  //     setOpen(true);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [location]);

  return (
    <div>
      <Headernew />
      <Slidershow />

      {/* <BookingDialog open={open} setOpen={setOpen} /> */}
      <div
        style={{
          marginRight: 30,
        }}
      >
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <CategorySubjects />
        </div>
        <div style={{ marginBottom: 20 }}>
          <AboutTeacher />
        </div>
      </div>
      <div>
        <Countries />
      </div>
      <div style={{ marginBottom: 20 }}>
        <Testimonial />
      </div>
      <div style={{ marginBottom: 20 }}>
        <Achievement />
      </div>

      <div style={{ marginBottom: 20 }}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
