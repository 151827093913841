import React, { useState } from "react";
import { Form, Input, Select, Button, DatePicker, Switch } from "antd";
import api from "../helpers/api";
import SnackbarAlert from "../Components/SnackbarAlert";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const CareersForm = ({ getSubjects, timeZones }) => {
  const [form] = Form.useForm();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertMessage, setalertMessage] = useState("");

  let formRef = React.createRef();
  const onFinish = async (values) => {
    const formData = {
      ...values,
      phone: `${values.prefix || +91}${values.phone}`,
      dob:values.dob ? values.dob.format() : undefined,
      isPan: values.isPan === undefined ? true : values.isPan,
      isWifi: values.isWifi === undefined ? false : values.isWifi,
    };

    try {
      const data = await api.post("/careers", formData);

      if (data.status === 200) {
        setAlertOpen(true);
        setAlertColor("success");
        setalertMessage(data.data.message);
        form.resetFields()
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        setAlertOpen(true);
        setAlertColor("error");
        setalertMessage(error.response?.data?.error);
      }
    }
  };

  console.log();

  const prefixSelector = (
    <Form.Item
      name="prefix"
      noStyle
    >
      <Select
        style={{
          width: 70,
        }}
        defaultValue={'+91'}
      >
        <Option value="+1">+1</Option>
        <Option value="+91">+91</Option>
        <Option value="+61">+61</Option>
        <Option value="+65">+65</Option>
        <Option value="+44">+44</Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        ref={formRef}
      >
        <p style={{ fontSize: 16, paddingBottom: 5 }}>First Name</p>
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter valid first name",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <p style={{ fontSize: 16, paddingBottom: 5 }}>Last Name</p>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter valid last name",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Position</p>

        <Form.Item
          hasFeedback
          name="position"
          rules={[
            {
              required: true,
              message: "Please select position!",
            },
          ]}
        >
          <Select placeholder="Select Position">
            <Option value={"Bharatanatyam Teacher"}>
              Bharatanatyam Teacher
            </Option>
            <Option value={"Carnatic Music Teacher"}>
              Carnatic Music Teacher
            </Option>
            <Option value={"Kathak Teacher"}> Kathak Teacher </Option>
            <Option value={"Hindustani Music Teacher"}>
              Hindustani Music Teacher
            </Option>
            <Option value={"Chess Teacher"}> Chess Teacher </Option>
            <Option value={"Vedic math teacher"}> Vedic math teacher </Option>
            <Option value={"Telugu Teacher"}> Telugu Teacher </Option>
            <Option value={"Hindi Teacher"}> Hindi Teacher </Option>
            <Option value={"Sanskrit Teacher"}> Sanskrit Teacher </Option>
            {/* <Option value={"Manager"}>Manager</Option>
            <Option value={"Sales Executive"}>Sales Executive</Option>
            <Option value={"Teacher"}>Teacher</Option>
            <Option value={"Accountant"}>Accountant</Option>
            <Option value={"Software Engineer"}>
              Software Engineer (React.JS and Node.JS)
            </Option> */}
          </Select>
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Date of birth</p>
        <Form.Item
          name="dob"
          rules={[
            {
              required: true,
              message: "Date of birth is required!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Email</p>
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Please enter valid email",
            },
            {
              required: true,
              message: "Email is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>WhatsApp Number</p>

        <Form.Item
          name="phone"
          hasFeedback
          type="number"
          rules={[
            {
              required: true,
              message: "Please enter valid whatsapp number",
            },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
            {
              pattern: /^[\d]{10,10}$/,
              message: "Value should be less than 10 character",
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item name="isPan" valuePropName="checked">
          <Switch defaultChecked /> Do you have a PAN card?
        </Form.Item>
        <Form.Item name="isWifi" valuePropName="checked">
          <Switch /> Do you have a Wifi connection? 
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Languages Known</p>
        <Form.Item
          name="languages"
          rules={[
            {
              required: true,
              message: "Please enter Languages known",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="English, Telugu ...." />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>
          Online teaching Experience in months
        </p>
        <Form.Item
          name="experience"
          type="number"
          rules={[
            {
              required: true,
              message: "Please enter valid Experience",
            },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Qualifications</p>
        <Form.Item
          name="qualifications"
          rules={[
            {
              required: true,
              message: "Please enter valid Qualifications",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Awards</p>
        <Form.Item
          name="awards"
          hasFeedback
        >
          <TextArea showCount maxLength={100} />
        </Form.Item>

        <p style={{ fontSize: 16, paddingBottom: 5 }}>Certifications</p>
        <Form.Item
          name="certifications"
          hasFeedback
        >
          <TextArea showCount maxLength={100} />
        </Form.Item>

        <Button
          htmlType="submit"
          type="submit"
          style={{
            width: "100%",
            marginTop: 20,
            backgroundColor: "#3867d6",
            color: "white",
            height: 50,
            borderRadius: 10,
            marginBottom: 10,
          }}
          variant="contained"
        >
          Apply
        </Button>
      </Form>

      <SnackbarAlert
        setOpen={setAlertOpen}
        open={alertOpen}
        message={alertMessage}
        color={alertColor}
      />
    </>
  );
};

export default CareersForm;
