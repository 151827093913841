import React, { useState } from "react";
import styles from "./styles.module.css";
import getWindowDimensions from "../helpers/useDimensions";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Phone, Menu } from "react-feather";
import { Container, SwipeableDrawer } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  homeCard: {
    height: "100%",
    width: "100%",
    margin: 5,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  ImageCard: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "relative",
  },
  CardChip: {
    background: "#0984e3 0% 0% no-repeat padding-box",
    borderRadius: "0px 50px 50px 0px",
    textAlign: "left",
    font: "normal normal bold 14px/21px Comfortaa",
    color: "#FFFFFF",
    opacity: 1,
    marginTop: -62,
  },
  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  chipPosition: {
    position: "absolute",
    zIndex: 10,
  },
  logoText: {
    fontWeight: "bold",
    fontSize: 35,
    marginBottom: 5,
    color: "white",
    textShadow: "5px 5px 19px rgba(53, 53, 53, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },

  logoImg: {
    height: 100,
    width: 100,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  flexResponsive: {
    flex: 0.23,
    [theme.breakpoints.down("xs")]: {
      flex: 0.1,
    },
  },
  mobileNavLinks: {
    marginBottom: 30,
    fontSize: 14,
    color: "white",
  },
  list: {
    width: 250,
    minHeight: "100vh",
    backgroundColor: "#090A41",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
const Header = ({ type, hide }) => {
  const { width } = getWindowDimensions();
  const classes = useStyles();
  const [menuListOpen, setMenuListOpen] = useState(false);
  const [drawerActive, setDrawerActive] = useState({
    right: false,
  });
  const handleMobileClick = () => {
    setMenuListOpen(!menuListOpen);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerActive({ ...drawerActive, [anchor]: open });
  };
  const mobileDrawer = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "right",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ width: 200 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "200px",
          marginTop: 30,
        }}
      >
        <div></div>
        <ClearIcon onClick={toggleDrawer(anchor, false)} style={{}} />
      </div>

      <div style={{ marginTop: 20 }}>
        <Link
          onClick={toggleDrawer(anchor, false)}
          style={{ color: "white" }}
          to="/"
        >
          <h4 className={classes.mobileNavLinks}>Home</h4>
        </Link>
        <Link
          onClick={toggleDrawer(anchor, false)}
          style={{ color: "white" }}
          to="/about"
        >
          <h4 className={classes.mobileNavLinks}>About</h4>
        </Link>
        <Link
          onClick={toggleDrawer(anchor, false)}
          style={{ color: "white" }}
          to="/booking"
        >
          <h4 className={classes.mobileNavLinks}>Book a Demo</h4>
        </Link>
        <Link
          onClick={toggleDrawer(anchor, false)}
          style={{ color: "white" }}
          to="/apply"
        >
          <h4 className={classes.mobileNavLinks}>Careers</h4>
        </Link>
      </div>
    </div>
  );

  return (
    <div>
      {width < 650 ? (
        <div
          className="navigation"
          onClick={() => {
            setDrawerActive({ right: true });
          }}
        >
          <input
            type="checkbox"
            className="navigation__checkbox"
            id="navi-toggle"
          />
          <label
            htmlFor="navi-toggle"
            className="navigation__button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <span className="navigation__icon">&nbsp;</span> */}
            <Menu />
          </label>
        </div>
      ) : null}
      <SwipeableDrawer
        anchor={"right"}
        open={drawerActive["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {mobileDrawer("right")}
      </SwipeableDrawer>
      <div
        style={{
          backgroundColor:
            type === "new"
              ? "rgba(15, 84, 131, 0.8)"
              : "rgba(15, 84, 131, 0.445)",
        }}
      >
        <Container
          style={{
            display: "flex",
            justifyContent: width < 650 ? "center" : "space-between",
            alignItems: "center",
            color: "white",
            paddingTop: 20,
            height: 90,
            width: "100%",
            paddingBottom: 20,
          }}
        >
          <div className={classes.flexResponsive}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: -25,
            }}
          >
            <div
              style={{
                // flex: width < 650 ? 1 : 0.6,
                textAlign: width > 650 ? "" : "center",
              }}
            >
              <div>
                <p className={classes.logoText}>Live Sloka</p>
                <p
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    textShadow: "4px 4px 30px rgba(85, 110, 110, 1)",
                  }}
                >
                  Online Classes
                </p>
              </div>
            </div>
          </div>

          {width > 650 ? (
            <div className={styles.iconsContainer} style={{ flex: 0.23 }}>
              <div className={styles.icons}>
                <div>
                  <Phone className={styles.realIcon} />
                </div>

                <div>
                  <p>Call Us</p>
                  <p style={{ fontWeight: "bold" }}>+91 830-9341208</p>
                </div>
              </div>
            </div>
          ) : null}
        </Container>
      </div>
      {width > 650 ? (
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            paddingTop: 30,
          }}
        >
          <div
            className={styles.navbar}
            style={{
              boxShadow:
                type === "new" ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <Link
                to="/"
                className={styles.menuLink}
                style={{ color: type === "new" ? "black" : "" }}
              >
                <p>Home</p>
              </Link>
              <Link
                to="/about"
                className={styles.menuLink}
                style={{ color: type === "new" ? "black" : "" }}
              >
                <p>About</p>
              </Link>
              <Link
                to="/booking"
                className={styles.menuLink}
                style={{ color: type === "new" ? "black" : "" }}
              >
                <p>Book a Demo</p>
              </Link>
              <Link
                to="/teachers"
                className={styles.menuLink}
                style={{ color: type === "new" ? "black" : "" }}
              >
                <p>Teachers</p>
              </Link>

              <Link
                to="/apply"
                className={styles.menuLink}
                style={{ color: type === "new" ? "black" : "" }}
              >
                <p>Careers</p>
              </Link>
            </div>

            <div className={styles.navBtn}>
              <p className={styles.navText}>Learn Indian Art forms Online</p>
            </div>
          </div>
        </Container>
      ) : null}

      <p
        style={{
          textAlign: "center",
          fontSize: width > 650 ? 24 : 16,
          color: "rgb(9, 132, 227)",
          marginTop: 20,
          fontWeight: "bold",
          display: hide ? "none" : "",
        }}
      >
        All our classes are available for kids and adults
      </p>
    </div>
  );
};

export default Header;
