export const teachersData = [
  // {
  //   id: 4,
  //   image:
  //     "https://static.wixstatic.com/media/e68f76_1c3cec522af546379bd546cf84b244a5~mv2.jpg/v1/fill/w_350,h_440,al_c,q_80,usm_0.66_1.00_0.01/IMG_20210207_105756_414%20(1).webp",
  //   name: "Amritha",
  //   desc: "Masters in Bharatanatyam",
  //   role: "Bharatanatyam Teacher",
  //   more: "Epitome of Professionalism, Commitment, Remarkable students progress.",
  // },
  {
    id: 1,
    image: "/assets/Madhuri.jpeg",
    name: "Madhuri",
    desc: "Masters in Telugu",
    role: "Sloka Teacher",
    more: "Well versed in chants and slokas from Sri Vaishnava Sampradaya.",
  },

  {
    id: 3,
    image:
      "https://static.wixstatic.com/media/e68f76_95f7f8964b274d53b66138926668756b~mv2.jpg/v1/fill/w_350,h_440,al_c,lg_1,q_80/WhatsApp%252520Image%2525202021-01-16%252520at.webp",
    name: "Aishwarya",
    role: "Bharatanatyam Teacher",
    desc: "Masters in Bharatanatyam",
    more: "Guinness Record holder, kids favorite Teacher",
  },

  {
    id: 5,
    image: "/assets/Pooja.jpeg",
    name: "Pooja",
    role: "Carnatic Teacher",
    desc: "Masters in Music,",
    more: "Gifted voice, Blessed by her Gurus (Kasaravalli Sisters)",
  },

  {
    id: 6,
    image:
      "https://static.wixstatic.com/media/e68f76_819cb8ac5b7547298371d7d9f4b9a6f8~mv2.jpeg/v1/crop/x_40,y_0,w_411,h_517/fill/w_350,h_440,al_c,q_80,usm_0.66_1.00_0.01/WhatsApp%20Image%202021-01-16%20at%2010_18_48%20AM.webp",
    name: "Ganesh",
    role: "Bollywood Teacher",
    desc: "Choreographer",
    more: "Choreographer from Bollywood Film Industry with several Years of Teaching experience",
  },

  {
    id: 8,
    image:
      "https://static.wixstatic.com/media/e68f76_310a1a5241d54e8a8fe1404809d416ed~mv2.jpeg/v1/crop/x_0,y_144,w_641,h_805/fill/w_350,h_440,al_c,q_80,usm_0.66_1.00_0.01/WhatsApp%20Image%202021-04-07%20at%2011_03_44%20AM.webp",
    name: "Soloman Raj",
    role: "Piano Teacher",
    desc: "Keyboard Player",
    more: "Certified from nathaniel school of music. Playing keyboard from past 8 years.",
  },
  {
    id: 9,
    image:
      "https://static.wixstatic.com/media/e68f76_3a7f1657460f418384958ec13a8beac2~mv2.jpg/v1/crop/x_65,y_0,w_509,h_640/fill/w_350,h_440,al_c,q_80,usm_0.66_1.00_0.01/pp.webp",
    name: "Gopinathacharyulu",
    role: "Sloka Teacher",
    desc: "Masters in Telugu & Sanskrit",
    more: "Being a prist in temple he's Well versed in chants and slokas from Sri Vaishnava Sampradaya.",
  },
  {
    id: 10,
    image:
      "https://static.wixstatic.com/media/e68f76_97909ac20c3245e6a0199fbdcc27612a~mv2.jpg/v1/crop/x_62,y_0,w_480,h_603/fill/w_350,h_440,al_c,q_80,usm_0.66_1.00_0.01/pp%20(1).webp",
    name: "Savitri",
    role: "Sloka Teacher",
    desc: "Masters in Telugu",
    more: "Trained in Classical Music and well versed in chants and slokas from Sri Vaishnava Sampradaya.",
  },
  {
    id: 11,
    image: "/assets/Subhadip-Hindustani.jpeg",
    name: "Subhadip",
    role: "Hindustani Teacher",
    desc: "Masters in Music",
    more: "Magical Voice, Several Prestigious Awards Winner in Hindustani Classical Music",
  },
  {
    id: 12,
    image: "/assets/Poonam.jpeg",
    name: "Poonam",
    role: "Hindi Teacher",
    desc: "Masters in Hindi",
    more: "Magical Voice, Several Prestigious Awards Winner in Hindustani Classical Music",
  },
  {
    id: 13,
    image: "/assets/Kishan.jpeg",
    name: "Kishan",
    role: "Carnatic Teacher",
    desc: "Masters in Music",
    more: "A blend of Classical Music with Instrumental touch takes your musical learning experience to another level.",
  },
  {
    id: 14,
    image: "/assets/Sajini.jpeg",
    name: "Sajini",
    role: "Bharatanatyam Teacher",
    desc: "Masters in Music",
    more: "A collection of grace and talent, teaching the profound art of Bharatanatyam with a simplicity.",
  },
  {
    id: 15,
    image: "/assets/Padma Pirya.jpeg",
    name: "Padma Priya",
    role: "Carnatic Teacher",
    desc: "Masters in Music",
    more: "A doctorate in Carnatic Music Vocals speaks volumes about her dedication and determination.",
  },
  {
    id: 16,
    image: "/assets/Krithikka.jpeg",
    name: "Krithikka",
    role: "Carnatic Teacher",
    desc: "Masters in Music",
    more: "Magical Voice, Several Prestigious Awards Winner in Hindustani Classical Music",
  },
];
