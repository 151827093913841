import { Button, Chip, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { makeStyles } from "@material-ui/core/styles";
import "./Nav.css";
import { Link, useParams } from "react-router-dom";
import Footer from "./Footer";
import subjectData from "./subjectData";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  formCont: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ecf0f1",
    borderRadius: 10,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  image: {
    height: "100%",
    width: 300,
    objectFit: "cover",
  },
  objectCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  enrollBtn: {
    width: 300,
    height: 50,
    border: "2px solid #0984e3",
    color: "#0984e3",
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,

    "&:hover": {
      backgroundColor: "#0984e3",
      color: "white",
    },
  },
  CardChip: {
    height: 70,
    width: 250,
    background: "#0984e3 0% 0% no-repeat padding-box",
    borderRadius: "0px 50px 50px 0px",
    textAlign: "left",
    fontSize: 24,
    fontWeight: "bold",
    color: "#FFFFFF",
    opacity: 1,
  },
  instructorImage: {
    height: "100%",
    width: 150,
    objectFit: "cover",
    borderRadius: 10,
  },
  imageFlex: {
    display: "flex",
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  logoImg: {
    height: 100,
    width: 100,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
const Aboutus = () => {
  const params = useParams();
  const classes = useStyles();
  const [teachersDatabyId, setTeachersDatabyId] = useState();
  useEffect(() => {
    subjectData.forEach((data) => {
      if (data.id === parseInt(params.id)) {
        setTeachersDatabyId(data);
      }
    });
  }, [params.id]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.overlay}>
          <Header />
          {teachersDatabyId && (
            <Container style={{ paddingBottom: 20 }}>
              <div className={classes.formCont}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <div>
                      <div>
                        <Chip
                          label={teachersDatabyId.chipName}
                          className={classes.CardChip}
                          style={{
                            backgroundColor: teachersDatabyId.color,
                            color:
                              teachersDatabyId.chipName === "Piano"
                                ? "black"
                                : "white",
                          }}
                        />
                        <div style={{ margin: 20 }}>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 500,
                              marginBottom: 20,
                            }}
                          >
                            {teachersDatabyId.about}
                          </p>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              width: "55%",
                              marginBottom: 20,
                            }}
                          >
                            {teachersDatabyId.desc}
                          </p>

                          <div>
                            <p style={{ fontWeight: "bold" }}>Timings</p>

                            <div style={{ marginLeft: 30, marginTop: 20 }}>
                              <p>{teachersDatabyId.timings}</p>
                            </div>
                          </div>

                          <div>
                            <p style={{ fontWeight: "bold", marginTop: 20 }}>
                              Class Structure
                            </p>

                            <div style={{ marginLeft: 30, marginTop: 20 }}>
                              {/* <p style={{ margin: 5 }}>
                              Day 1 : Self introduction day( Students) and
                              explanation of Bharatanatyam and the Namaskara.
                            </p>
                            <p style={{ margin: 5 }}>
                              Day 1 : Self introduction day( Students) and
                              explanation of Bharatanatyam and the Namaskara.
                            </p>{" "} */}

                              <ul>
                                {teachersDatabyId.classStructure.map((i) => (
                                  <li>{i}</li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div>
                            <p style={{ fontWeight: "bold", marginTop: 20 }}>
                              Instructors
                            </p>
                            <div className={classes.imageFlex}>
                              {teachersDatabyId?.instructors?.map((ins) => (
                                <div style={{ marginLeft: 30, marginTop: 20 }}>
                                  <img
                                    className={classes.instructorImage}
                                    src={ins.image}
                                    alt=""
                                  />
                                  <p style={{ fontWeight: "bold" }}>
                                    {ins.teacherName}
                                  </p>
                                  {teachersDatabyId.chipName}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className={classes.objectCenter}>
                      {/* <img
                        className={classes.image}
                        src="https://firebasestorage.googleapis.com/v0/b/livesloka-93a02.appspot.com/o/image%2Fjpeg%2FWhatsApp%20Image%202021-05-11%20at%2011.28.30%20AM%20(1).jpeg?alt=media&token=fb2faf51-7fbf-4528-8f20-8a61584141b5"
                        alt=""
                      /> */}
                      <Link
                        to="/contact"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button className={classes.enrollBtn}>
                          Enroll Now
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
